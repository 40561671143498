import React, { useState } from 'react';
import CommonModal from 'jbc-front/components/CommonModal';

type ContextType = {
  hideModal?: any;
  isOpen?: any;
};

const { Provider, Consumer } = React.createContext<ContextType>({});

export const Modal = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const funcs = {
    showModal: () => setIsOpen(true),
    hideModal: () => setIsOpen(false),
    toogleModal: () => setIsOpen(!isOpen),
    isOpen
  };
  return <Provider value={funcs}>{children(funcs)}</Provider>;
};

Modal.Header = ({ children, ...rest }) => (
  <Consumer>
    {({ hideModal }) => (
      <CommonModal.Header hideModal={hideModal} {...rest}>
        {children}
      </CommonModal.Header>
    )}
  </Consumer>
);
Modal.Modal = ({ children, ...rest }) => (
  <Consumer>
    {({ isOpen }) => (
      <CommonModal isOpen={isOpen} {...rest}>
        {children}
      </CommonModal>
    )}
  </Consumer>
);
Modal.Body = CommonModal.Body;
Modal.Footer = CommonModal.Footer;
Modal.Buttons = CommonModal.Buttons;
