import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { autofill, getFormValues, reset, getFormMeta } from 'redux-form';
import { AdditionalSearchFields } from './AdditionalSearchFields';
import SortingFieldsWithResult from './SortingFieldsWithResult';
import styles from '../components/SearchForm.scss';
import { useFormName, useQuery, useSession, reduxForm, searchDetector } from '../components';
import useReactRouter from 'use-react-router';
import { MainFormControls } from 'jbc-front/components/SearchForm';
import { saveDisplayEmployeeLimit, getSavedDisplayEmployeeLimit } from '../components/utils';
import { convertFormToQueryString, convertQueryToForm, getCurrentQueryFromLocation, hasConditions } from './utils';
import compose from 'lodash/fp/compose';
import moment from 'moment';
import _ from 'lodash';

export const SearchForm = ({ handleSubmit, query, variables, statusField }) => {
  const [isExpand, setIsExpand] = useState(false);
  const dispatch = useDispatch();
  const { formName } = useFormName();
  const { location, history } = useReactRouter();
  const clearForm = () => {
    if (formName !== null) {
      dispatch(reset(formName));
      history.push({ ...location, search: '' });
    }
  };
  const { data, loading } = useQuery(query, { variables });
  const formValues = useSelector(formName !== null ? getFormValues(formName) : _ => {});
  const {
    location: { pathname }
  } = useReactRouter();
  const { user } = useSession();
  const totalCount = _.get(data, 'client.employees.totalCount', 0);

  return (
    <div className={styles.searchForm}>
      <form onSubmit={handleSubmit}>
        <div className={styles.mainSearchFields}>
          {statusField ? statusField(handleSubmit) : <div />}
          <MainFormControls
            isExpand={isExpand}
            hasConditions={hasConditions(formValues)}
            handleClear={clearForm}
            onToggleClick={() => {
              setIsExpand(isExpand => !isExpand);
            }}
          />
        </div>
        {isExpand && <AdditionalSearchFields />}
        <SortingFieldsWithResult
          limit={getSavedDisplayEmployeeLimit(pathname, user)}
          onChangeLimit={newLimit => {
            saveDisplayEmployeeLimit(pathname, user, newLimit);
            handleSubmit();
          }}
          onChangeSortType={handleSubmit}
          count={totalCount}
          sortName="sortType"
          isLoading={loading}
        />
      </form>
    </div>
  );
};

const validate = values => {
  const errors = { age_to: '', age_from: '' };
  if (values.age_from && values.age_to && +values.age_from > +values.age_to) {
    errors.age_to = errors.age_from = 'の範囲が正しくありません';
  }
  ['joined_at', 'retired_at', 'birthday'].forEach(prefix => {
    if (
      [values[`${prefix}_start`], values[`${prefix}_end`]].every(
        date => date && moment(date, 'YYYY/MM/DD').isValid()
      ) &&
      moment(values[`${prefix}_start`], 'YYYY/MM/DD').diff(moment(values[`${prefix}_end`], 'YYYY/MM/DD'), 'days') > 0
    ) {
      errors[`${prefix}_start`] = errors[`${prefix}_end`] = 'の範囲が正しくありません';
    }
  });
  return errors;
};

export default formName =>
  compose(
    searchDetector({ convertFormToQueryString, convertQueryToForm, getCurrentQueryFromLocation }),
    reduxForm({
      form: formName,
      enableReinitialize: true,
      validate,
      onSubmit(_, dispatch, { handleSearch }) {
        dispatch((dispatch, getState) => {
          const meta: any = getFormMeta(formName)(getState());
          const autofilled: boolean = meta?.page?.autofilled;
          if (!autofilled) {
            dispatch(autofill(formName, 'page', 1));
          }
          handleSearch(getFormValues(formName)(getState()));
        });
      }
    })
  )(SearchForm);
