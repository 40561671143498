import React from 'react';
import Select from 'react-select';
import { isArray } from 'lodash';

export const OfficesSelectBox = ({ input: inputProps, options }) => (
  <div style={{ width: '400px' }}>
    <Select
      multi
      options={options}
      closeOnSelect={false}
      placeholder="適用事業所選択"
      name={inputProps.name}
      value={isArray(inputProps.value) && inputProps.value.map(v => options.find(o => o.value === v))}
      onChange={value => {
        const values = value?.map(v => v.value) || null;
        inputProps.onChange(values);
        inputProps.onBlur(values);
      }}
    />
  </div>
);
