import React from 'react';
import styles from './AsyncTaskError.scss';
import { recordDisplayDate } from './utils/recordDisplay';
import _ from 'lodash';
import { Close } from 'jbc-front/components/icons';
import { useAsyncTask } from './AsyncTask';

const title = {
  send_request: 'ストレスチェックの送信でエラー',
  resend_request: '受検勧奨メールの送信でエラー'
};

export const AsyncTaskError = () => {
  const { task, confirm } = useAsyncTask();
  return task && task.status === 'failed' ? (
    <div className={styles.errorWrap}>
      <div onClick={confirm} className={styles.close} role="presentation">
        <Close size={20} />
      </div>
      <div className={styles.inner}>
        <p className={styles.title}>
          {title[task.taskType]}
          が発生しました。
        </p>
        <div className={styles.dates}>
          <dl>
            <dt>開始日時：</dt>
            <dd>{recordDisplayDate(task.excutedAt, 'LLL')}</dd>
          </dl>
          <dl>
            <dt>エラー日時：</dt>
            <dd>{recordDisplayDate(task.endedAt, 'LLL')}</dd>
          </dl>
        </div>
        <ul className={styles.list}>
          {_.split(task.error, '\n').map((error, idx) => (
            <li key={idx}>{error}</li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;
};

export default AsyncTaskError;
