import React from 'react';
import { Office, Question, BrandLogo, User, Pulldown } from 'jbc-front/components/icons';
import styles from './Header.scss';
import './DropDownMenu.scss';
import { DropDownMenu } from './DropDownMenu';
import { useSession } from './SessionProvider';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import useReactRouter from 'use-react-router';

const NotVerified = () => (
  <div className={styles.notVerified}>
    メール送信機能が制限されています。
    <br />
    機能解放にはご利用の労務HRでのお手続きが必要です。
  </div>
);

export const Header = ({ showNavi }) => {
  const { user, client } = useSession();
  const { history } = useReactRouter();
  const userName = user?.employee ? `${user.employee.lastName} ${user.employee.firstName}` : user?.name || user?.email;
  const honor = userName ? '様' : '';

  const menuItems = [{ label: 'ログアウト', onClick: () => history.push('/logout') }].filter(_.identity);

  return (
    <React.Fragment>
      <div className={styles.headerStickyWrap}>
        <div className={styles.base}>
          <Link to="/dashboard" className={styles.link}>
            <div className={styles.logo}>
              <BrandLogo className={styles.brand} />
              <div className={styles.logoText}>ストレスチェック</div>
            </div>
          </Link>
          {showNavi && (
            <>
              {client && !client.isVerified && <NotVerified />}
              <div className={styles.help}>
                <a
                  className={styles.menu}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://jobcan-lms.zendesk.com/hc/ja/categories/360004802512">
                  <Question size={20} />
                  ヘルプ
                </a>
              </div>
              {client?.name && (
                <div className={styles.client}>
                  <Office size={30} />
                  <span className={styles.email}>{client.name}</span>
                </div>
              )}
              <div className={styles.user}>
                <DropDownMenu menuItems={menuItems}>
                  <User size={30} />
                  <span className={styles.email}>
                    {userName}&nbsp;{honor}&nbsp;
                  </span>
                  <span className={styles.pulldown}>
                    <Pulldown size={10} />
                    &nbsp;
                  </span>
                </DropDownMenu>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
