import React from 'react';
import 'spinkit/spinkit.min.css';

const Loading: React.FC<any> = props => (
  <div className="sk-circle" {...props}>
    <div className="sk-circle-dot"></div>
    <div className="sk-circle-dot"></div>
    <div className="sk-circle-dot"></div>
    <div className="sk-circle-dot"></div>
    <div className="sk-circle-dot"></div>
    <div className="sk-circle-dot"></div>
    <div className="sk-circle-dot"></div>
    <div className="sk-circle-dot"></div>
    <div className="sk-circle-dot"></div>
    <div className="sk-circle-dot"></div>
    <div className="sk-circle-dot"></div>
    <div className="sk-circle-dot"></div>
  </div>
);

export default Loading;
