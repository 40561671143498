import React, { useState } from 'react';
import { Section, TextField } from 'jbc-front/components/Form';
import { reduxForm } from 'redux-form';
import Button from 'jbc-front/components/Button';
import { useNotify } from '../actions';
import { requestResetPassword } from '../api';
import * as validators from '../validators';
import { AxiosError } from 'axios';

const Email = ({ required }) => (
  <TextField
    name="email"
    label="メールアドレス"
    validate={[validators.maxLength(255), validators.email]}
    required={required}
  />
);

const ForgotPasswordForm: any = ({ handleClickCollapse, shouldShowCidInput, submitting, handleSubmit }) => (
  <form>
    <div className="l-title-wrap">
      <h1 className="m-title-main">メールアドレスを入力してください</h1>
    </div>
    <div className="basic_information">
      <Section>
        <Email required />
        {shouldShowCidInput && <TextField name="client_code" label="会社ID" />}
        <a className="u-txt-link" onClick={() => handleClickCollapse(shouldShowCidInput)}>
          {shouldShowCidInput ? '会社IDの入力を閉じる' : '複数の会社に登録されていますか？'}
        </a>
      </Section>
      <div className="u-ta-c u-mt30">
        <Button onClick={handleSubmit} primary disabled={submitting}>
          送信
        </Button>
      </div>
    </div>
  </form>
);

const ConnectedForm: any = reduxForm({
  form: 'change_password'
})(ForgotPasswordForm);

export const ForgotPassword = ({ history }) => {
  const [shouldShowCidInput, setShouldShowCidInput] = useState(false);
  const notify = useNotify();
  const onSubmit = async values => {
    try {
      await requestResetPassword(values);
      history.push('/login');
      notify('送信しました', 'success');
    } catch (err) {
      let errorMessage = 'エラーが発生しました';
      if (typeof err === 'object' && err !== null && 'response' in err) {
        const errorResponse = err as AxiosError<{ error?: string }>;
        if (errorResponse.response && errorResponse.response.data && errorResponse.response.data.error) {
          errorMessage = errorResponse.response.data.error;
        }
      }
      notify(errorMessage, 'error');
    }
  };
  return (
    <ConnectedForm
      handleClickCollapse={shouldShowCidInput => setShouldShowCidInput(!shouldShowCidInput)}
      shouldShowCidInput={shouldShowCidInput}
      submitting={false}
      onSubmit={values => onSubmit(values)}
    />
  );
};
