import React, { useState } from 'react';
import { useQuery, LoadingPage } from '../components';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import styles from './SurveyFormDetail.scss';
import _ from 'lodash';
import Select from 'react-select';

const SURVEY_FORM = gql`
  query surveyForm($surveyFormId: Int!) {
    surveyForm(surveyFormId: $surveyFormId) {
      id
      title
      surveyFormQuestionCategories {
        id
        categoryNumber
        categoryTitles {
          id
          language
          description
        }
        surveyFormQuestions {
          id
          questionNumber
          questionTitles {
            id
            language
            title
          }
          surveyFormChildQuestions {
            id
            questionNumber
            childQuestionTitles {
              id
              language
              title
            }
          }
        }
      }
    }
  }
`;

const ALPHABETS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export const languageOptions = [
  { label: '日本語', value: 'japanese' },
  { label: 'English', value: 'english' }
];

export const SurveyFormDetail = ({
  match: {
    params: { id }
  }
}) => {
  const { data, loading } = useQuery(SURVEY_FORM, {
    variables: { surveyFormId: parseInt(id) },
    fetchPolicy: 'no-cache'
  });
  const form = data?.surveyForm;
  const [language, setLanguage] = useState('japanese');
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">調査フォーム</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.main}>
            <div className="l-breadcrumb">
              <Link to="/settings" className="l-breadcrumb-link">
                設定
              </Link>
              <span className="l-breadcrumb-here">
                <Link to="/settings/survey_forms" className="l-breadcrumb-link">
                  調査フォーム
                </Link>
              </span>
              <span className="l-breadcrumb-here">{form?.title}</span>
            </div>
            {loading ? (
              <LoadingPage />
            ) : !data?.surveyForm ? (
              <div className="l-wrap-xs u-pt100">
                <div className="l-box-message">
                  <p className="m-title-sub u-pt0 u-ta-c">調査フォームを取得できませんでした。</p>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.formField}>
                  <div className={styles.title}>{form.title}</div>
                  <div className={styles.field}>
                    {form.surveyFormQuestionCategories.map(category => (
                      <div key={category.categoryNumber} className={styles.category}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.categoryNumber}>
                            {ALPHABETS[parseInt(category.categoryNumber) - 1]}
                          </div>
                          <div className={styles.categoryDescription}>
                            {_.find(category.categoryTitles, { language: language }).description}
                          </div>
                        </div>
                        <div className={styles.questions}>
                          {category.surveyFormQuestions.map(question => (
                            <div key={question.questionNumber} className={styles.question}>
                              <div className={styles.questionTitle}>
                                {`${question.questionNumber}. ${
                                  _.find(question.questionTitles, { language: language }).title
                                }`}
                              </div>
                              <div className={styles.childQuestions}>
                                {question.surveyFormChildQuestions.map(childQuestion => (
                                  <div key={childQuestion.questionNumber} className={styles.childQuestion}>
                                    {_.find(childQuestion.childQuestionTitles, { language: language }).title}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.selectLanguage}>
                  <Select
                    name="SelectLanguage"
                    className={styles.select}
                    simpleValue
                    required
                    clearable={false}
                    value={{ label: _.find(languageOptions, { value: language })?.label, value: language }}
                    options={languageOptions}
                    onChange={async (selected: any) => {
                      setLanguage(selected);
                    }}
                  />
                </div>
                <div className={styles.buttonWrap}>
                  <Link to="/settings/survey_forms" className={styles.backButton}>
                    閉じる
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
