import React, { useState } from 'react';
import { Modal, useMutation, reduxForm, useQuery, ASYNC_TASK_FRAGMENT } from '../components';
import { gql } from '@apollo/client';
import styles from './BulkResend.scss';
import { useNotify } from '../actions';
import { Email } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';
import { SelectField } from 'jbc-front/components/Form';

const REQUEST_OPTION = gql`
  query request_option($mailType: Int!) {
    client {
      id
      mails(mailType: $mailType) {
        id
        name
        title
        content
        updatedAt
      }
      asyncTask {
        ...AsyncTaskFields
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const RESEND_REQUEST = gql`
  mutation resend_request($input: ResendRequestInput!) {
    resendRequest(input: $input) {
      client {
        id
      }
    }
  }
`;

export const BulkResend = ({ selectedSurvey, targetCount, disabled }) => {
  const requestOptions = useQuery(REQUEST_OPTION, {
    variables: { mailType: 2 },
    fetchPolicy: 'network-only'
  });
  if (requestOptions.loading) {
    return (
      <Button className={styles.showModal} icon={<Email className={styles.icon} />} disabled={true}>
        未回答者に受検勧奨メールを送る
      </Button>
    );
  }
  const taskRunning = ['waiting', 'in_progress'].includes(requestOptions.data.client.asyncTask?.status);
  const mails = requestOptions.data.client.mails;
  const mailOptions = mails.map(mail => {
    return {
      label: mail.name,
      value: mail.id
    };
  });

  return (
    <Modal>
      {({ hideModal, showModal, isOpen }) => {
        const [selectedEmail, setSelectedEmail] = useState(mailOptions[0].value);
        const {
          mutation,
          value: { loading }
        } = useMutation(RESEND_REQUEST);
        const notify = useNotify();
        const sendForm = async () => {
          if (selectedEmail) {
            await mutation({
              variables: {
                input: {
                  implementationSurveyId: selectedSurvey.id,
                  mailId: parseInt(selectedEmail, 10)
                }
              }
            });
            hideModal();
            await requestOptions.refetch();
          } else {
            notify('メール内容を選択してください', 'error');
          }
        };
        return (
          <>
            <Button
              className={styles.showModal}
              icon={<Email className={[styles.icon, !disabled && styles.ableIcon].join(' ')} />}
              onClick={() => {
                showModal();
              }}
              disabled={loading || disabled}>
              未回答者に受検勧奨メールを送る
            </Button>

            {isOpen && (
              <form>
                <Modal.Modal>
                  <Modal.Header hideModal={hideModal}>受検勧奨メールを送る</Modal.Header>
                  <Modal.Body>
                    <div className={styles.modalBody}>
                      <div className={styles.sendCount}>送信人数：{targetCount}人（未回答者）</div>
                      <div className={styles.mailField}>
                        <SelectField
                          input={{
                            value: selectedEmail,
                            onChange: value => {
                              setSelectedEmail(value);
                            }
                          }}
                          label="メール内容"
                          name="Mail"
                          simpleValue
                          required
                          clearable={false}
                          options={mailOptions}
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className={styles.modalFooter}>
                      <Modal.Buttons>
                        <Button className={styles.cancelButton} onClick={hideModal}>
                          キャンセル
                        </Button>
                        <Button
                          className={styles.sendButton}
                          primary
                          onClick={sendForm}
                          disabledReason={taskRunning ? 'ストレスチェック送信中のため送信できません' : null}
                          disabled={!selectedEmail || loading || taskRunning}>
                          送信
                        </Button>
                      </Modal.Buttons>
                    </div>
                  </Modal.Footer>
                </Modal.Modal>
              </form>
            )}
          </>
        );
      }}
    </Modal>
  );
};

export const BulkResendForm = reduxForm({
  form: 'BulkResendForm',
  enableReinitialize: true
})(BulkResend);
