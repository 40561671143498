import React, { useState } from 'react';
import { Modal } from '../components';
import styles from './DownloadModal.scss';
import { useNotify } from '../actions';
import { saveAs } from 'file-saver';
import NProgress from 'nprogress';
import Button from 'jbc-front/components/Button';
import { exportSurveyResult } from '../api';
import { isErrorWithMessage } from '../components';

const RadioField = ({
  label,
  options,
  state,
  setState,
  note = null as any,
  radioNote = null as any,
  disabled: allDisabled = false
}) => (
  <div className={styles.field}>
    <div className={styles.label}>{label}</div>
    <div className={styles.note}>{note}</div>
    <div>
      {options.map(({ label, value, disabled: optionDisabled }) => {
        const disabled = allDisabled || optionDisabled;
        return <InputRadio {...{ label, value, disabled, state, setState }} key={value} />;
      })}
    </div>
    {radioNote && <div className={styles.note}>{radioNote}</div>}
  </div>
);

const InputRadio = ({ label, value, disabled, state, setState }) => (
  <div style={{ display: 'inline-block' }} className={'input-label'} key={value}>
    <label>
      <input
        type="radio"
        value={value}
        checked={value === state}
        disabled={disabled}
        className={`m-radio-input${disabled ? ' disabled' : ''}`}
        onClick={() => setState(value)}
      />
      <span className={`m-radio-parts${disabled ? ' disabled' : ''}`}>{label}</span>
    </label>
  </div>
);

const downloadOptions = [
  { value: 'UTF-8', label: 'UTF-8（推奨）' },
  { value: 'Windows-31J', label: 'Shift-JIS' }
];

const targetOptions = [
  { value: 'all', label: '全従業員' },
  { value: 'search', label: '検索絞込中の従業員' }
];

const DownloadModal = ({ selectedSurvey, employeeSurveyIds, isPractitioner }) => (
  <Modal>
    {({ hideModal, showModal, isOpen }) => {
      const [encoding, setEncoding] = useState('UTF-8');
      const [target, setTarget] = useState('all');
      const [item, setItem] = useState('scores');
      const [loading, setLoading] = useState(false);
      const notify = useNotify();
      const itemOptions = [
        { value: 'scores', label: '従業員のストレス評価' },
        { value: 'scores_and_answers', label: '従業員のストレス評価と回答', disabled: !isPractitioner }
      ];
      const exportResult = async () => {
        NProgress.start();
        setLoading(true);
        try {
          await exportSurveyResult({
            surveyId: selectedSurvey.id,
            employeeSurveyIds: employeeSurveyIds,
            encoding: encoding,
            target: target,
            item: item
          }).then(res => {
            saveAs(res.data, decodeURIComponent(res.headers['content-disposition'].match(/filename="(.*)"/)[1]));
          });
          hideModal();
        } catch (err) {
          let errorMessage;
          if (isErrorWithMessage(err, '422')) {
            errorMessage = '使用できない文字が含まれています。';
          } else if (isErrorWithMessage(err, '400')) {
            errorMessage = 'encodingは[UTF-8][Windows-31J]いずれかを設定してください';
          } else {
            errorMessage = '予期せぬエラーが発生しました';
          }
          notify(errorMessage, 'error');
        } finally {
          NProgress.done();
          setLoading(false);
        }
      };
      return (
        <>
          <Button widthAuto className={styles.downloadButton} onClick={() => showModal()}>
            結果ダウンロード
          </Button>
          {isOpen && (
            <form>
              <Modal.Modal>
                <div className={styles.modal}>
                  <Modal.Header hideModal={hideModal}>ストレスチェック結果のダウンロード</Modal.Header>
                  <Modal.Body>
                    <RadioField
                      label="ファイル形式選択"
                      options={downloadOptions}
                      state={encoding}
                      setState={setEncoding}
                      radioNote="Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。"
                    />
                    <RadioField
                      label="ダウンロード対象選択"
                      options={targetOptions}
                      state={target}
                      setState={setTarget}
                      note={
                        <React.Fragment>
                          全従業員：全従業員の結果をダウンロードします。
                          <br />
                          検索絞込中の従業員：個人結果で検索絞込した従業員の結果をダウンロードします。
                        </React.Fragment>
                      }
                    />
                    <RadioField label="ダウンロード項目選択" options={itemOptions} state={item} setState={setItem} />
                  </Modal.Body>
                  <Modal.Footer>
                    <div className={styles.modalFooter}>
                      <Modal.Buttons>
                        <Button className={styles.cancelButton} onClick={hideModal}>
                          キャンセル
                        </Button>
                        <Button className={styles.sendButton} primary onClick={() => exportResult()} disabled={loading}>
                          ダウンロード
                        </Button>
                      </Modal.Buttons>
                    </div>
                  </Modal.Footer>
                </div>
              </Modal.Modal>
            </form>
          )}
        </>
      );
    }}
  </Modal>
);

export default DownloadModal;
