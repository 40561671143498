import React from 'react';
import { MdChevronRight } from 'react-icons/md';
import styles from './ListGroup.scss';
import { Link } from 'react-router-dom';

export const ListGroup = ({ children, ...rest }) => (
  <div className={styles.group} {...rest}>
    {children}
  </div>
);

export const ListTitle = ({ children, ...rest }) => (
  <div className={styles.title} {...rest}>
    {children}
  </div>
);

export const ListItem = ({ children, to, onClick = null, href = null, as, ...rest }) => {
  const Component = as || (to && Link) || (href && 'a') || 'div';

  return (
    <Component className={styles.item} onClick={onClick} href={href} to={to} {...rest}>
      {children}
    </Component>
  );
};

export const LinkIcon = () => (
  <div className={styles.arrow}>
    <MdChevronRight size={20} color="#ccc" />
  </div>
);
