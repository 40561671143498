import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AddEmployeeSurvey.scss';
import Button from 'jbc-front/components/Button';
import moment from 'moment';

const isFutureDate = date => {
  return date && moment(date).endOf('days').isAfter(moment());
};

const AddEmployeeSurvey = ({ selectedSurvey }) => {
  const disabled = !isFutureDate(selectedSurvey.surveyEndDate);
  return (
    <Button
      widthAuto
      className={`${styles.addButton} ${disabled && styles.disabledButton}`}
      disabled={disabled}
      disabledReason="実施期間外です"
      as={disabled ? Button : Link}
      to={{ pathname: '/employees', state: { selectedSurvey: selectedSurvey } }}>
      従業員を追加する
    </Button>
  );
};

export default AddEmployeeSurvey;
