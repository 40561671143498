import React from 'react';
import Loading from './Loading';
import styles from './LoadingPage.scss';

export const LoadingPage = () => (
  <div className="l-wrap-xs u-pt100">
    <div className={styles.loading}>
      <Loading />
    </div>
  </div>
);
