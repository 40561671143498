import { Survey } from '../../entity';
import moment from 'moment';

export const getTitle = (survey: Survey) => {
  if (survey) {
    const createdAt = moment(survey.createdAt).format('YYYY/MM/DD');
    return `${createdAt}　${survey.surveyForm.title} 実施`;
  } else {
    return '';
  }
};
