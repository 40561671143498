import React, { useState } from 'react';
import CommonModal from 'jbc-front/components/CommonModal';

type ContextType = {
  hideModal?: any;
  showType?: string;
};

const { Provider, Consumer } = React.createContext<ContextType>({});

export const MultiModal = ({ children }) => {
  const [showType, setShowType] = useState('none');
  const funcs = {
    showModal: type => setShowType(type),
    hideModal: () => setShowType('none'),
    showType
  };
  return <Provider value={funcs}>{children(funcs)}</Provider>;
};

MultiModal.Header = ({ children, ...rest }) => (
  <Consumer>
    {({ hideModal }) => (
      <CommonModal.Header hideModal={hideModal} {...rest}>
        {children}
      </CommonModal.Header>
    )}
  </Consumer>
);
MultiModal.Modal = ({ type, children, ...rest }) => (
  <Consumer>
    {({ showType }) => (
      <CommonModal isOpen={showType === type} {...rest}>
        {children}
      </CommonModal>
    )}
  </Consumer>
);
MultiModal.Body = CommonModal.Body;
MultiModal.Footer = CommonModal.Footer;
MultiModal.Buttons = CommonModal.Buttons;
