import React, { useState } from 'react';
import SidebarOrigin from 'jbc-front/components/Sidebar';
import { Link } from 'react-router-dom';
import {
  Dashboard,
  StressCheck,
  StressCheckGroupResult,
  Setting,
  PhysicianIntroduction,
  Close,
  Hamburger,
  BlankTarget
} from 'jbc-front/components/icons';
import styles from './Sidebar.scss';
import { useOutsideClick } from 'jbc-front/components/hooks/useOutsideClick';

const LINK_TO_PHYSICIAN_INTRODUCTION =
  'https://jobcan-lms.zendesk.com/hc/ja/articles/360051564892-%E7%94%A3%E6%A5%AD%E5%8C%BB%E7%B4%B9%E4%BB%8B%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%AE%E3%81%94%E7%B4%B9%E4%BB%8B';

export const Sidebar = ({ offset }: any) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleClickOutside = () => {
    setIsSidebarOpen(false);
  };

  const containerRef = useOutsideClick(handleClickOutside, 'mousedown');

  return (
    <div ref={containerRef} className={styles.fixation}>
      <SidebarOrigin offset={Infinity} position="absolute" isOpen={isSidebarOpen}>
        <SidebarOrigin.Item as={Link} to={'/dashboard'} icon={Dashboard} onClick={() => setIsSidebarOpen(false)}>
          ダッシュボード
        </SidebarOrigin.Item>
        <SidebarOrigin.Item
          as={Link}
          to={'/personal_results'}
          icon={StressCheck}
          onClick={() => setIsSidebarOpen(false)}>
          個人結果
        </SidebarOrigin.Item>
        <SidebarOrigin.Item
          as={Link}
          to={'/group_result'}
          icon={StressCheckGroupResult}
          onClick={() => setIsSidebarOpen(false)}>
          集団分析
        </SidebarOrigin.Item>
        <SidebarOrigin.Item as={Link} to="/settings" icon={Setting} onClick={() => setIsSidebarOpen(false)}>
          設定
        </SidebarOrigin.Item>
        <div className={styles.itemWrap}>
          <a
            className={styles.itemRightIcon}
            target="_blank"
            rel="noopener noreferrer"
            href={LINK_TO_PHYSICIAN_INTRODUCTION}
            onClick={() => setIsSidebarOpen(false)}>
            <PhysicianIntroduction size={20} />
            産業医紹介
            <BlankTarget size={16} style={{ float: 'right' }} />
          </a>
        </div>
      </SidebarOrigin>
      <div
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        role="button"
        onKeyPress={() => setIsSidebarOpen(!isSidebarOpen)}
        tabIndex={0}
        className={styles.menuBtn}>
        {isSidebarOpen ? <Close size={20} /> : <Hamburger size={20} />}
      </div>
    </div>
  );
};
