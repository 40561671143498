import React from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { PaginatorWithResult as PaginatorWithResultOrig } from 'jbc-front/components/SearchForm';
import { useAutofill, useFormName, useFormValue } from './FormName';
import { useDisplayEmployeeLimit } from './utils';

interface PaginatorWithResultProps {
  count: number;
  isLoading: boolean;
  failureMessage?: string;
}

export const PaginatorWithResult: React.FC<PaginatorWithResultProps> = ({
  failureMessage = '従業員が見つかりませんでした',
  ...rest
}) => {
  const { autofill } = useAutofill();
  const { formName } = useFormName();
  const dispatch = useDispatch();
  const page = useFormValue('page')!;
  const [limit] = useDisplayEmployeeLimit();
  const handlePagination = page => {
    autofill('page', page.toString());
    dispatch(submit(formName!));
  };

  return (
    <PaginatorWithResultOrig
      page={page}
      limit={limit}
      onClick={handlePagination}
      failureMessage={failureMessage}
      {...rest}
    />
  );
};
