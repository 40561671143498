import { addNotification } from 'reapop';
import { useDispatch } from 'react-redux';

export const notify = (message: any, status: 'success' | 'error' = 'success') =>
  addNotification({
    message: `${message}`,
    position: 'tc',
    status,
    dismissAfter: 3000
  });

export const useNotify = () => {
  const dispatch = useDispatch();
  return (message: any, status: any) => dispatch(notify(message, status));
};
