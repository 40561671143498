import React, { Component, FC, useState } from 'react';
import './DropDownMenu.scss';
import onClickOutside from 'react-onclickoutside';

type MenuItem = { label: string; onClick: () => void };
type MenuItemsProps = { menuItems: MenuItem[]; closeMenu: () => void };

class _MenuItems extends Component<MenuItemsProps> {
  handleClickOutside = () => {
    this.props.closeMenu();
  };

  render() {
    const { menuItems } = this.props;
    return (
      <div className="dd-menu-items">
        <div className="dd-menu-items-button">
          {menuItems.map(({ label, onClick }) => (
            <button
              onClick={() => {
                onClick();
                this.props.closeMenu();
              }}
              className="clickable"
              key={label}>
              {label}
            </button>
          ))}
        </div>
      </div>
    );
  }
}

const MenuItems = onClickOutside(_MenuItems);

type DropDownMenuProps = { menuItems: MenuItem[] };

export const DropDownMenu: FC<DropDownMenuProps> = ({ menuItems, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="c-dropdown">
      <div className="dd-menu ignore-react-onclickoutside">
        <div className="dd-menu-toggle" onClick={() => setIsOpen(!isOpen)}>
          {children}
        </div>
        {isOpen && <MenuItems menuItems={menuItems} closeMenu={() => setIsOpen(false)} />}
      </div>
    </div>
  );
};
