import React, { useState } from 'react';
import { TextField, Section } from 'jbc-front/components/Form';
import { reduxForm } from 'redux-form';
import Button from 'jbc-front/components/Button';
import { resetPassword } from '../api';
import { parse } from 'query-string';
import { useNotify } from '../actions';
import { password } from '../validators';

const ResetPasswordForm = ({ submitting, handleSubmit }) => (
  <form>
    <div className="l-title-wrap">
      <h1 className="m-title-main">新しいパスワードを入力してください</h1>
    </div>
    <div className="basic_information">
      <Section>
        <TextField name="password" label="新パスワード" type="password" validate={password} required />
        <TextField
          name="password_confirmation"
          label="新パスワード(確認用)"
          type="password"
          validate={password}
          required
        />
      </Section>
      <div className="u-ta-c u-mt30">
        <Button onClick={handleSubmit} primary disabled={submitting}>
          変更
        </Button>
      </div>
    </div>
  </form>
);

const ConnectedForm = reduxForm({
  form: 'change_password'
})(ResetPasswordForm);

export const ResetPassword = ({ location: { search }, history }) => {
  const [submitting, setSubmitting] = useState(false);
  const { token } = parse(search);
  const notify = useNotify();
  const handleSubmit = async (token, values) => {
    setSubmitting(true);
    if (values.password && values.password_confirmation && values.password !== values.password_confirmation) {
      notify('パスワード(確認用)が一致しません', 'error');
    } else {
      await resetPassword({
        token: token,
        password: values.password,
        password_confirmation: values.password_confirmation
      })
        .then(() => {
          history.push('/login');
          notify('変更しました', 'success');
        })
        .catch(err => {
          if (err.message.endsWith('400')) {
            notify('トークンまたはパスワードが不正です', 'error');
          } else {
            notify('予期せぬエラーが発生しました', 'error');
          }
        });
    }
    setSubmitting(false);
  };
  return (
    <ConnectedForm
      submitting={submitting}
      onSubmit={values => {
        handleSubmit(token, values);
      }}
    />
  );
};
