import React from 'react';
import styles from './NotFound.scss';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">ストレスチェック</h1>
      </div>
      <div className="l-wrap-l l-contents-wrap">
        <div className={styles.main}>
          <section className={styles.section}>
            <div className={styles.mainMessage}>お探しのページは見つかりませんでした</div>
            <div className={styles.subMessage}>
              URLに誤りがないかご確認ください。
              <div className={styles.link}>
                <Link to="/dashboard" className="u-txt-link">
                  ダッシュボードに戻る
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
