import React from 'react';
import { SearchResult, SortingFields } from 'jbc-front/components/SearchForm';
import { useFormValue } from '../components/FormName';
import styles from './SortingFieldsWithResult.scss';
import DownloadModal from './DownloadModal';
import AddEmployeeSurvey from './AddEmployeeSurvey';

const limits = ['10', '20', '50', '100'].map(ele => ({ value: ele, label: `${ele}件を表示` }));
const sortTypes = [
  { value: 'staff_code__asc', label: 'スタッフコード 昇順' },
  { value: 'staff_code__desc', label: 'スタッフコード 降順' },
  { value: 'full_name_kana__asc', label: '氏名 昇順' },
  { value: 'full_name_kana__desc', label: '氏名 降順' }
];

const SortingFieldsWithResultWrap = ({
  sortName,
  limit,
  count,
  isLoading,
  onChangeLimit,
  onChangeSortType,
  selectedSurvey,
  employeeSurveyIds,
  isPractitioner
}) => {
  const page = useFormValue('page') || 1;
  return (
    <div className={styles.sortingFieldsWithResult}>
      <div className={styles.left}>
        <DownloadModal
          selectedSurvey={selectedSurvey}
          employeeSurveyIds={employeeSurveyIds}
          isPractitioner={isPractitioner}
        />
        <AddEmployeeSurvey selectedSurvey={selectedSurvey} />
      </div>
      <div className={styles.center}>
        <SearchResult
          total={count}
          page={page}
          limit={limit}
          isLoading={isLoading}
          failureMessage="従業員が見つかりませんでした"
          className={styles.resultCenter}
        />
      </div>
      <div className={styles.right}>
        <SortingFields
          limits={limits}
          sortTypes={sortTypes}
          onChangeLimit={onChangeLimit}
          onChangeSortType={onChangeSortType}
          sortName={sortName}
        />
      </div>
    </div>
  );
};
export default SortingFieldsWithResultWrap;
