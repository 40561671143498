import { useEffect, useState } from 'react';
import { useAsyncTask } from '../components';

type TaskType = Array<'send_request' | 'resend_request'>;
type CallbackType = Array<() => void>;

const useCallbackAfterTask = (taskTypes: TaskType, callbacks: CallbackType) => {
  const { task } = useAsyncTask();
  const [observing, setObserving] = useState<boolean>(false);
  const isRelevantTaskType = taskTypes.includes(task?.taskType);
  const isTaskDone = ['success', 'failed'].includes(task?.status);

  useEffect(() => {
    if (observing && isRelevantTaskType && isTaskDone) {
      callbacks.forEach((cb: () => void) => cb());
      setObserving(false);
    } else if (isRelevantTaskType && !isTaskDone) {
      setObserving(true);
    }
  }, [task]);
};

export default useCallbackAfterTask;
