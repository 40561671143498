import React from 'react';
import { SortingFieldsWithResult } from 'jbc-front/components/SearchForm';
import { useFormValue } from '../components/FormName';
import useReactRouter from 'use-react-router';
import styles from './SortingFieldsWithResult.scss';

const limits = ['10', '20', '50', '100'].map(ele => ({ value: ele, label: `${ele}件を表示` }));
const sortTypes = [
  { value: 'staff_code__asc', label: 'スタッフコード 昇順' },
  { value: 'staff_code__desc', label: 'スタッフコード 降順' },
  { value: 'name__asc', label: '氏名 昇順' },
  { value: 'name__desc', label: '氏名 降順' },
  { value: 'email__asc', label: 'メールアドレス 昇順' },
  { value: 'email__desc', label: 'メールアドレス 降順' }
];

const SortingFieldsWithResultWrap = ({
  sortName,
  limit,
  count,
  isLoading,
  onChangeLimit,
  onChangeSortType,
  ...rest
}) => {
  const { location } = useReactRouter();
  const page = useFormValue('page') || 1;
  return (
    <div className={styles.sortingFieldsWithResult}>
      <SortingFieldsWithResult
        {...{
          sortName,
          sortTypes,
          limits,
          pathname: location.pathname,
          page,
          limit,
          count,
          isLoading,
          onChangeLimit,
          onChangeSortType,
          failureMessage: '管理者が見つかりませんでした',
          ...rest
        }}
      />
    </div>
  );
};
export default SortingFieldsWithResultWrap;
