import React from 'react';
import useReactRouter from 'use-react-router';
import { useSession } from './SessionProvider';

export const searchDetector =
  ({ convertQueryToForm, getCurrentQueryFromLocation, convertFormToQueryString }) =>
  SearchForm =>
  props => {
    const { location, history } = useReactRouter();
    const { user } = useSession();
    const handleSearch = values => {
      const search = convertFormToQueryString(values);
      history.push({ ...location, search });
    };
    const query = getCurrentQueryFromLocation(location, user);
    return <SearchForm initialValues={convertQueryToForm(query)} {...props} handleSearch={handleSearch} />;
  };

export default searchDetector;
