import _ from 'lodash';
import fp from 'lodash/fp';
import queryString from 'query-string';
import { formValueSelector, getFormValues, autofill as autofillForm } from 'redux-form';
import { getSavedDisplayEmployeeLimit, pipe } from '../components/utils';

export const formName = 'userSearchTop';
export const selector = formValueSelector(formName);
export const getValues = getFormValues(formName);
export const autofill = autofillForm.bind(null, formName);
export const defaultQuery = {
  role_type: 'all',
  _page: '1',
  sort_type: 'staff_code__asc'
};

export const convertQueryToForm = query => {
  return Object.assign(
    { sortType: `${query.sort}__${query.order}` },
    ...Object.entries(query)
      .map(([k, v]) => [k[0] === '_' ? `_${_.camelCase(k)}` : _.camelCase(k), v])
      .map(([k, v]) => convertQueryToFormField(k, v))
      .filter(_.identity)
  );
};

const convertQueryToFormField = (fieldName, value) => {
  switch (fieldName) {
    case '_page':
      return { page: value };
    case 'sort':
    case 'order':
      return undefined;
    default:
      return { [fieldName]: value };
  }
};

export const convertFormToQueryString = data =>
  queryString.stringify(convertFormToQuery(data), { arrayFormat: 'bracket' });

const convertFormToQuery = data => {
  if (data === undefined) return {};
  return Object.assign(
    {},
    ...Object.entries(data)
      .map(([k, v]) => [k[0] === '_' ? `_${_.snakeCase(k)}` : _.snakeCase(k), v])
      .map(([k, v]) => convertFormToQueryField(k, v))
      .filter(_.identity)
  );
};

const convertFormToQueryField = (fieldName, value) => {
  switch (fieldName) {
    case 'limit':
      return null;
    case 'page':
      return { _page: value };
    case 'sortType': {
      const [sort, order] = value.split('__');
      return { order, sort };
    }
    default:
      return { [fieldName]: value };
  }
};

export const getCurrentQueryFromLocation = (location, user) => {
  return {
    ...defaultQuery,
    ...queryString.parse(location.search, { arrayFormat: 'bracket' }),
    limit: getSavedDisplayEmployeeLimit(location.pathname, user)
  };
};

export const hasConditions = formValues => {
  return !_.isEqual(pipe(convertFormToQuery(formValues), fp.pickBy(fp.identity)), defaultQuery);
};

export const convertFormToGraphQl = formValues => {
  const [sortColumn, sortOrder] = (formValues.sortType || convertQueryToForm(defaultQuery).sortType).split('__');
  return {
    page: +formValues.page || 1,
    limit: +formValues.limit || 100,
    search: {
      ...(formValues.roleType !== 'all' && _.pick(formValues, 'roleType')),
      ..._.pick(formValues, 'q'),
      sortColumn,
      sortOrder
    }
  };
};
