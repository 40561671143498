import React from 'react';
import styles from './AsyncTask.scss';
import { Close } from 'jbc-front/components/icons';

const AsyncTaskLoading = ({ error, done, message, text, onCloseClick }) => (
  <div className={styles.wrap}>
    <div className={error ? styles.circleError : done ? styles.circleDone : styles.circle}>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <span className={styles.text}>{text}</span>
    </div>
    <div className={styles.message}>
      {message}
      {(error || done) && (
        <a className={styles.close} onClick={onCloseClick} onKeyPress={onCloseClick} role="link" tabIndex={0}>
          <Close />
        </a>
      )}
    </div>
  </div>
);

export default AsyncTaskLoading;
