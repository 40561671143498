import React from 'react';
import { Provider } from 'react-redux';
import configureStore, { history } from './configureStore';
import { ConnectedRouter } from 'connected-react-router';
import './App.css';
import { Route, Switch } from 'react-router';
import { ApolloProvider } from '@apollo/client';
import * as pages from './pages';
import Layout from './Layout';
import configureApollo from './configureApollo';
import { AsyncTask, SessionProvider, NotFound } from './components';
import 'font-awesome/scss/font-awesome.scss';
import 'jbc-front/styles/default.scss';
import { useNotify } from './actions';
import './rollbar';
import './datadog';

const store = configureStore({});
const client = configureApollo('/graphql', null);

const redirectToDashboard = ({ history }) => {
  const notify = useNotify();
  history.push('/dashboard');
  notify('ログイン済みです', 'success');
  return <div></div>;
};

const App = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <SessionProvider>
          <AsyncTask>
            <Layout sidebar showSession>
              <Switch>
                <Route path="/dashboard" exact component={pages.Dashboard} />
                <Route path="/dashboard/:displaySurvey" exact component={pages.Dashboard} />
                <Route path="/dashboard/:displaySurvey/:surveyId" exact component={pages.Dashboard} />
                <Route path="/employees" exact component={pages.Employees} />
                <Route path="/personal_results" exact component={pages.PersonalResults} />
                <Route path="/personal_results/:surveyId" exact component={pages.PersonalResults} />
                <Route path="/personal_results/details/:id" exact component={pages.PersonalResultDetail} />
                <Route path="/group_result" exact component={pages.GroupResult} />
                <Route path="/group_result/:surveyId" exact component={pages.GroupResult} />
                <Route path="/settings" exact component={pages.Settings} />
                <Route path="/settings/users" exact component={pages.Roles} />
                <Route path="/settings/implementation_surveys" exact component={pages.ImplementationSurveys} />
                <Route path="/settings/mails" exact component={pages.Mails} />
                <Route path="/settings/mails/create" exact component={pages.CreateMail} />
                <Route path="/settings/mails/edit/:id" exact component={pages.EditMail} />
                <Route path="/settings/survey_forms" exact component={pages.SurveyForms} />
                <Route path="/settings/survey_forms/:id" exact component={pages.SurveyFormDetail} />
                <Route path="/invitation_confirmation" exact component={redirectToDashboard} />
                <Route path="/login" exact component={redirectToDashboard} />
                <Route path="/reset_password" exact component={redirectToDashboard} />
                <Route path="/logout" exact component={pages.Logout} />
                <Route component={NotFound} />
              </Switch>
            </Layout>
          </AsyncTask>
        </SessionProvider>
      </ConnectedRouter>
    </Provider>
  </ApolloProvider>
);

export default App;
