import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { TextField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { BrandLogo } from 'jbc-front/components/icons';
import { Link } from 'react-router-dom';
import { doLogin } from '../api';
import styles from './Login.scss';
import { useNotify } from '../actions';
import { isErrorWithMessage } from '../components';

const LoginForm: any = ({ pristine, handleClickCollapse, shouldShowCidInput, submitting, handleSubmit }) => (
  <React.Fragment>
    <div className={styles.loginWrap}>
      <form className={styles.loginBox} onSubmit={handleSubmit}>
        <h1 className={styles.logo}>
          <div className={styles.logoBox}>
            <BrandLogo className={styles.brand} />
            <div className={styles.logoText}>ストレスチェック</div>
          </div>
        </h1>
        <p className={styles.loginTitle}>ログイン</p>
        <div className={styles.loginField}>
          <div className={styles.loginCollapseWrap} style={shouldShowCidInput ? { height: '170px' } : {}}>
            <TextField name="email" label="メールアドレス" />
            {shouldShowCidInput && <TextField name="client_code" label="会社ID" />}
            <a className={`${styles.loginCid} u-txt-link`} onClick={() => handleClickCollapse(shouldShowCidInput)}>
              {shouldShowCidInput ? '会社IDの入力を閉じる' : '複数の会社に登録されていますか？'}
            </a>
          </div>
          <TextField name="password" label="パスワード" type="password" />
          <div className={styles.loginButton}>
            <Button as="button" primary widthWide type="submit" disabled={pristine || submitting}>
              ログイン
            </Button>
          </div>
          <Link to="/forgot_password" className={styles.loginForgot}>
            パスワードをお忘れですか？
          </Link>
        </div>
      </form>
    </div>
  </React.Fragment>
);

const LoginFormContainer = reduxForm({
  form: 'loginForm',
  enableReinitialize: true
})(LoginForm);

export const Login = ({ history, rest }) => {
  const [submitting, setSubmitting] = useState(false);
  const [shouldShowCidInput, setShouldShowCidInput] = useState(false);
  const notify = useNotify();
  const handleSubmit = async values => {
    setSubmitting(true);
    try {
      await doLogin(values);
      history.push('/dashboard');
      window.location.reload();
      notify('ログインしました', 'success');
    } catch (err) {
      let errorMessage;
      if (isErrorWithMessage(err, '401')) {
        errorMessage = 'ログイン情報に誤りがあります';
      } else if (isErrorWithMessage(err, '400')) {
        errorMessage = '複数のアカウントが該当したため、会社IDの入力が必要です';
      } else {
        errorMessage = '予期せぬエラーが発生しました';
      }
      notify(errorMessage, 'error');
    }
    setSubmitting(false);
  };
  return (
    <LoginFormContainer
      handleClickCollapse={shouldShowCidInput => setShouldShowCidInput(!shouldShowCidInput)}
      shouldShowCidInput={shouldShowCidInput}
      submitting={submitting}
      onSubmit={handleSubmit}
      {...rest}
    />
  );
};
