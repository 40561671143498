import React, { useEffect } from 'react';
import { doLogout } from '../api';

export const Logout = () => {
  useEffect(() => {
    (async () => {
      await doLogout();
      window.location.reload();
    })();
  }, []);

  return <main className="u-ta-c u-pt100">ログアウトしています...</main>;
};
