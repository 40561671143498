import React from 'react';
import useReactRouter from 'use-react-router';
import { useQuery, LoadingPage } from '../components';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import styles from './SurveyForms.scss';

const SURVEY_FORMS = gql`
  query surveys {
    client {
      id
      surveyForms {
        id
        title
      }
    }
  }
`;

export const SurveyForms = () => {
  const { history } = useReactRouter();
  const { data, loading } = useQuery(SURVEY_FORMS, { fetchPolicy: 'network-only' });
  const forms: any[] = data?.client?.surveyForms || [];
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">調査フォーム</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.main}>
            <div className="l-breadcrumb">
              <Link to="/settings" className="l-breadcrumb-link">
                設定
              </Link>
              <span className="l-breadcrumb-here">調査フォーム</span>
            </div>
            {loading ? (
              <LoadingPage />
            ) : !data?.client ? (
              <div className="l-wrap-xs u-pt100">
                <div className="l-box-message">
                  <p className="m-title-sub u-pt0 u-ta-c">調査フォームを取得できませんでした。</p>
                </div>
              </div>
            ) : (
              <>
                <table className={styles.table}>
                  <thead>
                    <tr className={styles.header}>
                      <th className={styles.formNameColumn}>調査フォーム</th>
                    </tr>
                  </thead>
                  <tbody>
                    {forms.length > 0 &&
                      forms.map(form => (
                        <tr
                          key={form.id}
                          className={styles.row}
                          onClick={() => {
                            history.push(`/settings/survey_forms/${form.id}`);
                          }}>
                          <td>
                            <div className={styles.formTitle}>{form.title}</div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
