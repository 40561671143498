import React, { useState, useEffect } from 'react';
import { TextField, TextAreaField, SelectField, Label } from 'jbc-front/components/Form';
import { reduxForm } from '../components';
import Button from 'jbc-front/components/Button';
import { Link } from 'react-router-dom';
import styles from './MailForm.scss';

const mailTypes = [
  { value: 1, label: '依頼メール' },
  { value: 2, label: '勧奨メール' },
  { value: 3, label: '取消メール' }
];

const getDefaultContent = mailType => {
  switch (mailType) {
    case 1:
      return 'ストレスチェックの受検依頼が届きました。<br />下記のURLより確認をお願いします。<br /><br />受検期限：<u>受検期限</u><br />招待URL';
    case 2:
      return 'ストレスチェックの受検期限が迫っています。<br />下記のURLより確認をお願いします。<br /><br />受検期限：<u>受検期限</u><br />招待URL';
    case 3:
      return 'ストレスチェックが管理者により取り消されました。<br /><br />受検期限：<u>受検期限</u>';
    default:
      return '';
  }
};

const Form = ({ initialValues, onSubmit }) => {
  const ref = React.createRef<any>();
  const [name, setName] = useState('');
  const [mailType, setMailType] = useState(1);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [defaultContent, setDefaultContent] = useState(getDefaultContent(1));
  useEffect(() => {
    setName(initialValues?.name || '');
    setMailType(initialValues?.mailType || 1);
    setTitle(initialValues?.title || '');
    setContent(initialValues?.content || '');
  }, [initialValues]);
  useEffect(() => {
    setDefaultContent(getDefaultContent(mailType));
  }, [mailType]);
  const handleOnOpen = () => {
    const { current } = ref;
    if (current !== null) {
      current.scrollTop = current.scrollHeight;
    }
  };
  return (
    <form>
      <div className={styles.newMailField}>
        <div className={styles.forms}>
          <TextField name="name" label="管理ラベル" onChange={(_, value) => setName(value)} required />
          <SelectField
            input={{
              value: mailType,
              onChange: value => {
                setMailType(value);
              }
            }}
            name="mailType"
            simpleValue
            label="メール種別"
            options={mailTypes}
            onOpen={handleOnOpen}
            required
            disabled={initialValues?.isDefault}
          />
          <TextField name="title" label="件名" onChange={(_, value) => setTitle(value)} required />
          <Label text="デフォルト本文" />
          <div className={styles.body}>
            <p dangerouslySetInnerHTML={{ __html: defaultContent }} />
          </div>
          <TextAreaField name="content" label="カスタマイズ文" onChange={(_, value) => setContent(value)} required />
        </div>
        <div className={styles.description}>
          デフォルトの本文は編集できません。
          <br />
          {'※<b>と</b>の間に文字を入れると太字になります。'}
          <br />
          {'※<u>と</u>の間に文字を入れると下線がつきます。'}
        </div>
      </div>
      <div className={styles.buttons}>
        <div className={styles.buttonWrap}>
          <Link to="/settings/mails" className={styles.backButton}>
            キャンセル
          </Link>
        </div>
        <Button
          className={styles.createButton}
          onClick={() => {
            onSubmit({ name: name, mailType: mailType, title: title, content: content });
          }}
          primary>
          保存
        </Button>
      </div>
    </form>
  );
};

export const MailForm = reduxForm({
  form: 'CreateMailForm',
  enableReinitialize: true
})(Form);
