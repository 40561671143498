import axios from 'axios';

export function checkToken(token) {
  return axios.get(`/user?token=${token}`);
}

export function setPassword(params) {
  return axios.post('/user', params);
}

export function doLogin(params) {
  return axios.post('/user/login', params);
}

export function doLogout() {
  return axios.delete('/user/login');
}

export function requestResetPassword(params) {
  return axios.post('/user/request_reset_password', params);
}

export function resetPassword(params) {
  return axios.post('/user/reset_password', params);
}

export function exportSurveyResult(params) {
  return axios.get('/export_survey_result', { responseType: 'blob', params: params });
}
