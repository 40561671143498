import React from 'react';
import { Modal, useQuery, useMutation, LoadingPage, useSession } from '../components';
import Button from 'jbc-front/components/Button';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import styles from './ImplementationSurveys.scss';
import { Delete } from 'jbc-front/components/icons';
import { convertQueryToForm, convertFormToGraphQl, getCurrentQueryFromLocation } from '../users/utils';
import { useNotify } from '../actions';
import moment from 'moment';

const IMPLEMENTATION_SURVEYS = gql`
  query surveys {
    client {
      id
      implementationSurveys {
        id
        createdAt
        surveyEndDate
        surveyForm {
          title
        }
        requestMail {
          id
          name
        }
      }
    }
  }
`;

const DELETE_SURVEY = gql`
  mutation delete_survey($input: DeleteSurveyInput!) {
    deleteSurvey(input: $input) {
      client {
        id
        implementationSurveys {
          id
          createdAt
          surveyEndDate
          surveyForm {
            title
          }
          requestMail {
            id
            name
          }
        }
      }
    }
  }
`;

export const ImplementationSurveys = ({ location: { search, pathname } }) => {
  const notify = useNotify();
  const { user } = useSession();
  const formValues = convertQueryToForm(getCurrentQueryFromLocation({ search, pathname }, user));
  const variables = convertFormToGraphQl(formValues);
  const { data, loading } = useQuery(IMPLEMENTATION_SURVEYS, { variables, fetchPolicy: 'network-only' });
  const deleteSurvey = useMutation(DELETE_SURVEY);
  const surveys: any[] = data?.client?.implementationSurveys || [];
  const deleteFunc = async surveyId => {
    await deleteSurvey.mutation({
      variables: {
        input: {
          surveyId: surveyId
        }
      }
    });
    notify('削除しました', 'success');
  };
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">実施履歴</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.main}>
            <div className="l-breadcrumb">
              <Link to="/settings" className="l-breadcrumb-link">
                設定
              </Link>
              <span className="l-breadcrumb-here">実施履歴</span>
            </div>
            {loading ? (
              <LoadingPage />
            ) : !data?.client ? (
              <div className="l-wrap-xs u-pt100">
                <div className="l-box-message">
                  <p className="m-title-sub u-pt0 u-ta-c">実施履歴を取得できませんでした。</p>
                </div>
              </div>
            ) : (
              <>
                <div className="l-overflow-scroll">
                  <table className={styles.table}>
                    <thead>
                      <tr className={styles.header}>
                        <th className={styles.createdAtColumn}>送信日</th>
                        <th className={styles.endDateColumn}>実施期間</th>
                        <th className={styles.formColumn}>調査フォーム</th>
                        <th className={styles.mailColumn}>メール内容</th>
                        <th className={styles.deleteColumn}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {surveys.length > 0 &&
                        surveys.map(survey => (
                          <tr key={survey.id} className={styles.row}>
                            <td>{moment(survey.createdAt).format('YYYY/MM/DD')}</td>
                            <td>{`${moment(survey.surveyEndDate).format('YYYY/MM/DD')} まで`}</td>
                            <td>{survey.surveyForm.title}</td>
                            <td>{survey.requestMail.name}</td>
                            <td>
                              <Modal>
                                {({ hideModal, showModal, isOpen }) => {
                                  return (
                                    <>
                                      <div
                                        onClick={() => {
                                          showModal();
                                        }}>
                                        <span>
                                          <Delete size={20} className={styles.icon} />
                                        </span>
                                      </div>
                                      {isOpen && (
                                        <Modal.Modal>
                                          <div className={styles.modal}>
                                            <Modal.Header hideModal={hideModal}>削除の確認</Modal.Header>
                                            <Modal.Body>
                                              <div>
                                                削除されたストレスチェック結果は復元することができません。
                                                <br />
                                                従業員の個人結果も一緒に削除されますのでご注意ください。
                                              </div>
                                              <div className={styles.confirm}>本当に削除しますか</div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                              <div className={styles.modalFooter}>
                                                <Modal.Buttons>
                                                  <Button className={styles.cancelButton} onClick={hideModal}>
                                                    いいえ
                                                  </Button>
                                                  <Button
                                                    className={styles.deleteButton}
                                                    primary
                                                    onClick={() => deleteFunc(survey.id)}>
                                                    はい
                                                  </Button>
                                                </Modal.Buttons>
                                              </div>
                                            </Modal.Footer>
                                          </div>
                                        </Modal.Modal>
                                      )}
                                    </>
                                  );
                                }}
                              </Modal>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
