import React from 'react';
import { useQuery, useMutation, MailForm } from '../components';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import styles from './EditMail.scss';
import { useNotify } from '../actions';
import useReactRouter from 'use-react-router';

const GET_ORIGIN = gql`
  query getOrigin($mailId: Int!) {
    mail(mailId: $mailId) {
      id
      name
      isDefault
      mailType
      title
      content
    }
  }
`;

const UPDATE_MAIL = gql`
  mutation update_mail($input: UpdateMailInput!) {
    updateMail(input: $input) {
      client {
        id
        mails {
          id
          name
        }
      }
    }
  }
`;

export const EditMail = ({
  match: {
    params: { id }
  }
}) => {
  const notify = useNotify();
  const { history } = useReactRouter();
  const { data } = useQuery(GET_ORIGIN, {
    variables: { mailId: parseInt(id) },
    fetchPolicy: 'no-cache'
  });
  const initialValues = data?.mail;
  const updateMailFunc = useMutation(UPDATE_MAIL);
  const updateMail = async ({ name, mailType, title, content }) => {
    if (!name) {
      notify('管理ラベルを入力してください', 'error');
    } else if (!mailType) {
      notify('メール種別を入力してください', 'error');
    } else if (!title) {
      notify('件名を入力してください', 'error');
    } else if (!content) {
      notify('メール本文を入力してください', 'error');
    } else {
      await updateMailFunc.mutation({
        variables: {
          input: {
            mailId: parseInt(id),
            name: name,
            mailType: mailType,
            title: title,
            content: content
          }
        }
      });
      notify('変更が保存されました', 'success');
      history.push('/settings/mails');
    }
  };
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">メールカスタマイズ</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.main}>
            <div className="l-breadcrumb">
              <Link to="/settings" className="l-breadcrumb-link">
                設定
              </Link>
              <span className="l-breadcrumb-here">
                <Link to="/settings/mails" className="l-breadcrumb-link">
                  メールカスタマイズ
                </Link>
              </span>
              <span className="l-breadcrumb-here">編集</span>
            </div>
            <MailForm initialValues={initialValues} onSubmit={updateMail} />
          </div>
        </div>
      </div>
    </div>
  );
};
