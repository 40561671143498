import React, { useState } from 'react';
import { Balloon, Ul, Li, MultiModal, useMutation, reduxForm } from '../components';
import { gql } from '@apollo/client';
import styles from './AddUser.scss';
import { TextField, SelectField } from 'jbc-front/components/Form';
import { useNotify } from '../actions';
import { email, maxLength } from '../validators';
import Button from 'jbc-front/components/Button';
import { Pulldown } from 'jbc-front/components/icons';

const GET_LMS_USER = gql`
  mutation get_lms_user($input: GetLmsUserInput!) {
    getLmsUser(input: $input) {
      user {
        email
        name
        clientRole
        employee {
          commonId
          officeId
          staffCode
          firstName
          lastName
          firstNameKana
          lastNameKana
          gender
          officeName
        }
      }
    }
  }
`;

const ADD_LMS_USER = gql`
  mutation add_lms_user($input: AddLmsUserInput!) {
    addLmsUser(input: $input) {
      user {
        id
      }
    }
  }
`;

const ADD_NEW_USER = gql`
  mutation add_new_user($input: AddNewUserInput!) {
    addNewUser(input: $input) {
      user {
        id
      }
    }
  }
`;

const roleTypes = [
  { value: 'practitioner', label: '実施者' },
  { value: 'person_in_charge', label: '実務担当者' }
];

interface user {
  email: string;
  staffCode: string;
  name: string;
  clientRole: string;
}

const initialUser: user = {
  email: '',
  staffCode: '',
  name: '',
  clientRole: ''
};

const AddLmsUserModal = ({
  identifier,
  setIdentifier,
  user,
  setUser,
  roleType,
  setRoleType,
  hideModal,
  bodyRef,
  reset,
  handleOnOpen
}) => {
  const getLmsUserFunc = useMutation(GET_LMS_USER);
  const addLmsUserFunc = useMutation(ADD_LMS_USER);
  const notify = useNotify();
  const getLmsUser = async () => {
    if (identifier) {
      const res = await getLmsUserFunc.mutation({
        variables: {
          input: {
            identifier: identifier
          }
        }
      });
      const lmsUser = res.data.getLmsUser.user;
      setUser(lmsUser);
    } else {
      notify('メールアドレスまたはスタッフコードを入れてください', 'error');
    }
  };
  const addLmsUser = async () => {
    if (user.email) {
      if (user.employee) {
        await addLmsUserFunc.mutation({
          variables: {
            input: {
              email: user.email,
              name: user.name,
              roleType: roleType,
              employee: {
                commonId: user.employee.commonId,
                officeId: user.employee.officeId,
                email: user.email,
                staffCode: user.employee.staffCode,
                firstName: user.employee.firstName,
                lastName: user.employee.lastName,
                firstNameKana: user.employee.firstNameKana,
                lastNameKana: user.employee.lastNameKana,
                gender: user.employee.gender
              }
            }
          }
        });
      } else {
        await addLmsUserFunc.mutation({
          variables: {
            input: {
              email: user.email,
              name: user.name,
              roleType: roleType
            }
          }
        });
      }
      notify('招待メールを送信しました', 'success');
      setIdentifier(null);
      setUser(initialUser);
      setRoleType(roleTypes[1].value);
      reset();
      hideModal();
    } else {
      notify('管理者を選択してください', 'error');
    }
  };
  return (
    <MultiModal.Modal type="lms_user">
      <MultiModal.Header hideModal={hideModal}>管理者追加</MultiModal.Header>
      <MultiModal.Body ref={bodyRef}>
        <div className={styles.addLmsUserBody}>
          <div className={styles.searchForm}>
            <div className={styles.identifier}>
              <TextField
                name="identifier"
                onChange={(_, value) => setIdentifier(value)}
                placeholder="スタッフコード、メールアドレス(完全一致)"
              />
            </div>
            <div className={styles.searchField}>
              <Button className={styles.searchButton} primary onClick={getLmsUser} disabled={!identifier}>
                検索
              </Button>
            </div>
          </div>
          <div className="l-overflow-scroll">
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.emailColumn}>メールアドレス</th>
                  <th className={styles.staffCode}>スタッフコード</th>
                  <th className={styles.nameColumn}>氏名</th>
                  <th className={styles.roleType}>労務権限</th>
                </tr>
              </thead>
              {user.email && (
                <tbody>
                  <tr className={styles.row}>
                    <td>{user.email}</td>
                    <td>{user.employee ? user.employee.staffCode || '-' : '従業員登録なし'}</td>
                    <td>{user.employee ? `${user.employee.lastName} ${user.employee.firstName}` : user.name}</td>
                    <td>{user.clientRole}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className={styles.roleTypeField}>
            <SelectField
              input={{
                value: roleType,
                onChange: value => {
                  setRoleType(value);
                }
              }}
              name="role_type"
              simpleValue
              label="権限"
              options={roleTypes}
              onOpen={handleOnOpen}
              required
            />
          </div>
        </div>
      </MultiModal.Body>
      <MultiModal.Footer>
        <MultiModal.Buttons>
          <div className={styles.buttons}>
            <Button className={styles.cancelButton} onClick={hideModal}>
              キャンセル
            </Button>
            <Button
              className={styles.addButton}
              primary
              onClick={addLmsUser}
              disabled={!user.email || addLmsUserFunc.value.loading}>
              追加
            </Button>
          </div>
        </MultiModal.Buttons>
      </MultiModal.Footer>
    </MultiModal.Modal>
  );
};

const AddNewUserModal = ({
  userEmail,
  setEmail,
  userName,
  setName,
  roleType,
  setRoleType,
  hideModal,
  bodyRef,
  reset,
  handleOnOpen
}) => {
  const {
    mutation,
    value: { loading }
  } = useMutation(ADD_NEW_USER);
  const notify = useNotify();
  const addNewUser = async () => {
    if (!userEmail) {
      notify('メールアドレスを入力してください', 'error');
    } else {
      await mutation({
        variables: {
          input: {
            email: userEmail,
            name: userName,
            roleType: roleType
          }
        }
      });
      notify('アカウント招待メールを送信しました', 'success');
      setEmail(null);
      setName(null);
      setRoleType(roleTypes[0].value);
      reset();
      hideModal();
    }
  };
  return (
    <MultiModal.Modal type="new_user">
      <MultiModal.Header hideModal={hideModal}>管理者追加</MultiModal.Header>
      <MultiModal.Body ref={bodyRef}>
        <TextField
          name="email"
          label="メールアドレス"
          validate={email}
          onChange={(_, value) => setEmail(value)}
          required
        />
        <TextField name="name" label="アカウント名" validate={maxLength(20)} onChange={(_, value) => setName(value)} />
        <div className={styles.roleTypeField}>
          <SelectField
            input={{
              value: roleType,
              onChange: value => {
                setRoleType(value);
              }
            }}
            name="role_type"
            simpleValue
            label="権限"
            options={roleTypes}
            onOpen={handleOnOpen}
            required
          />
        </div>
      </MultiModal.Body>
      <MultiModal.Footer>
        <MultiModal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button primary onClick={addNewUser} disabled={!userEmail || loading}>
            送信
          </Button>
        </MultiModal.Buttons>
      </MultiModal.Footer>
    </MultiModal.Modal>
  );
};

const AddUser = ({ reset }) => {
  const [identifier, setIdentifier] = useState(null);
  const [user, setUser] = useState(initialUser);
  const [lmsRoleType, setLmsRoleType] = useState(roleTypes[1].value);
  const [userEmail, setEmail] = useState(null);
  const [userName, setName] = useState(null);
  const [newRoleType, setNewRoleType] = useState(roleTypes[0].value);
  return (
    <MultiModal>
      {({ hideModal, showModal, showType }) => {
        const ref = React.createRef<any>();
        const handleOnOpen = () => {
          const { current } = ref;
          if (current !== null) {
            current.scrollTop = current.scrollHeight;
          }
        };
        return (
          <div className={styles.balloon}>
            <Balloon
              right={true}
              center={false}
              switchRender={(toggle, _) => (
                <Button
                  className={styles.addUserButton}
                  onClick={() => {
                    toggle();
                  }}
                  primary>
                  管理者追加
                </Button>
              )}>
              {hide => (
                <React.Fragment>
                  <Ul className={styles.ul}>
                    <Li className={styles.links}>
                      <a
                        className={styles.a}
                        href="#"
                        onClick={() => {
                          hide();
                          showModal('lms_user');
                        }}>
                        <Pulldown size={10} className={styles.pulldown} />
                        労務HRの管理者から追加
                      </a>
                      <a
                        className={styles.a}
                        onClick={() => {
                          hide();
                          showModal('new_user');
                        }}>
                        <Pulldown size={10} className={styles.pulldown} />
                        新しい管理者を追加
                      </a>
                    </Li>
                  </Ul>
                </React.Fragment>
              )}
            </Balloon>
            <form>
              {showType === 'lms_user' && (
                <AddLmsUserModal
                  identifier={identifier}
                  setIdentifier={setIdentifier}
                  user={user}
                  setUser={setUser}
                  roleType={lmsRoleType}
                  setRoleType={setLmsRoleType}
                  hideModal={hideModal}
                  bodyRef={ref}
                  reset={reset}
                  handleOnOpen={handleOnOpen}
                />
              )}
              {showType === 'new_user' && (
                <AddNewUserModal
                  userEmail={userEmail}
                  setEmail={setEmail}
                  userName={userName}
                  setName={setName}
                  roleType={newRoleType}
                  setRoleType={setNewRoleType}
                  hideModal={hideModal}
                  bodyRef={ref}
                  reset={reset}
                  handleOnOpen={handleOnOpen}
                />
              )}
            </form>
          </div>
        );
      }}
    </MultiModal>
  );
};

export const AddUserForm = reduxForm({
  form: 'AddUserForm',
  enableReinitialize: true
})(AddUser);
