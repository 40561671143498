import { Survey, initialSurveyForm } from './Survey';

export interface EmployeeSurvey {
  id: number;
  isProvided: boolean;
  answerFinished: boolean;
  isNotable: boolean;
  implementationSurvey: Survey;
  employeeSurveyCalculatedValues: any[];
  employee: any;
  employeeSurveyComments: any[];
}

export const initialEmployeeSurvey: EmployeeSurvey = {
  id: 0,
  isProvided: true,
  answerFinished: true,
  isNotable: false,
  implementationSurvey: {
    id: 0,
    createdAt: new Date(),
    surveyEndDate: new Date(),
    surveyForm: initialSurveyForm,
    employeeSurveys: [],
    employeeCount: 0,
    answeredCount: 0,
    notableCount: 0
  },
  employeeSurveyCalculatedValues: [],
  employee: {},
  employeeSurveyComments: []
};
