import { combineEpics } from 'redux-observable';
import { debounceTime, filter, map } from 'rxjs/operators';
import { submit } from 'redux-form';
import { Action } from 'redux';
import { Observable } from 'rxjs';

const DEFAULT_EXCLUDE_FIELDS = ['limit', 'sortType', 'page'];

const search =
  (formName: any, excludeFields: any = DEFAULT_EXCLUDE_FIELDS) =>
  (action$: Observable<Action & any>) =>
    action$.pipe(
      filter(action => action.type && action.type.match(/@@redux-form\/CHANGE/) && action.meta.form === formName),
      filter(action => !excludeFields.includes(action.meta.field)),
      debounceTime(1000),
      map(() => submit(formName))
    );

const rootEpic = combineEpics(
  search('employeeSearchTop', ['status', ...DEFAULT_EXCLUDE_FIELDS]),
  search('employeeSurveySearchTop'),
  search('userSearchTop', ['roleType', ...DEFAULT_EXCLUDE_FIELDS])
);

export default rootEpic;
