import React, { Component, useState } from 'react';
import onClickOutside from 'react-onclickoutside';
import styles from './Balloon.scss';

type BalloonContentProps = {
  hide: () => void;
  right?: boolean;
  center?: boolean;
  children: React.ReactNode;
};

class _BalloonContent extends Component<BalloonContentProps> {
  handleClickOutside = () => {
    this.props.hide();
  };

  render() {
    const { right, center, children } = this.props;
    return <div className={(right ? styles.right : '') || (center ? styles.center : '')}>{children}</div>;
  }
}

const BalloonContent = onClickOutside(_BalloonContent);

type BalloonProps = {
  switchRender: (toggle: () => void, show: boolean) => React.ReactNode;
  children: React.ReactNode | ((hide: () => void) => React.ReactNode);
  right?: boolean;
  center?: boolean;
};

export const Balloon: React.FC<BalloonProps> = ({ switchRender, children, right, center }) => {
  const [show, setShow] = useState(false);
  const hide = () => setShow(false);
  const toggle = () => setShow(!show);

  return (
    <div className={styles.switch}>
      {switchRender(toggle, show)}
      {show && (
        <BalloonContent hide={hide} right={right} center={center}>
          {typeof children === 'function' ? children(hide) : children}
        </BalloonContent>
      )}
    </div>
  );
};

export const Ul = ({ children, ...rest }) => <ul {...rest}>{children}</ul>;

export const Li = ({ children, ...rest }) => (
  <li className={styles.li} {...rest}>
    {children}
  </li>
);
