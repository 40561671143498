import React, { useState } from 'react';
import { Modal, useMutation, reduxForm, useQuery, ASYNC_TASK_FRAGMENT } from '../components';
import gql from 'graphql-tag';
import styles from './ChangeDeadline.scss';
import { useNotify } from '../actions';
import Button from 'jbc-front/components/Button';
import { SelectField, DateField, CheckboxField } from 'jbc-front/components/Form';
import moment from 'moment';
import { futureDate } from '../validators';
import useReactRouter from 'use-react-router';

const REQUEST_OPTION = gql`
  query request_option($mailType: Int!) {
    client {
      id
      mails(mailType: $mailType) {
        id
        name
        title
        content
        updatedAt
      }
      asyncTask {
        ...AsyncTaskFields
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const CHANGE_DEADLINE_REQUEST = gql`
  mutation change_deadline($input: ChangeDeadlineInput!) {
    changeDeadline(input: $input) {
      client {
        implementationSurveys {
          id
          surveyEndDate
        }
      }
    }
  }
`;

const isFutureDate = date => {
  return date && moment(date).endOf('days').isAfter(moment());
};

export const ChangeDeadline = ({ selectedSurvey, targetCount, disabled, onUpdated, displayType }) => {
  const [surveyEndDate, setSurveyEndDate] = useState(null);
  const { history } = useReactRouter();

  const requestOptions = useQuery(REQUEST_OPTION, {
    variables: { mailType: 1 },
    fetchPolicy: 'network-only'
  });

  if (requestOptions.loading) {
    return (
      <Button className={styles.showModal} disabled={true}>
        実施期間を変更する
      </Button>
    );
  }

  const isTaskRunning = ['waiting', 'in_progress'].includes(requestOptions.data.client.asyncTask?.status);
  const mails = requestOptions.data.client.mails;

  const mailOptions = mails.map(mail => {
    return {
      label: mail.name,
      value: mail.id
    };
  });

  return (
    <Modal>
      {({ hideModal, showModal, isOpen }) => {
        const [selectedEmail, setSelectedEmail] = useState(mailOptions[0].value);
        const [isSendMail, setIsSendMail] = useState(false);

        const {
          mutation,
          value: { loading }
        } = useMutation(CHANGE_DEADLINE_REQUEST);

        const notify = useNotify();

        const sendForm = async () => {
          if (isFutureDate(surveyEndDate) && selectedEmail) {
            await mutation({
              variables: {
                input: {
                  implementationSurveyId: parseInt(selectedSurvey.id, 10),
                  mailId: parseInt(selectedEmail, 10),
                  surveyEndDate: surveyEndDate,
                  isSendMail: isSendMail
                }
              }
            });
            hideModal();
            await requestOptions.refetch();
            onUpdated();

            if (displayType === 'finishedSurveys' && targetCount > 0) {
              history.push(`/dashboard/unfinishedSurveys/${selectedSurvey.id}`);
            } else {
              history.push(`/dashboard/${displayType}/${selectedSurvey.id}`);
            }
          } else if (!isFutureDate(surveyEndDate)) {
            notify('実施期限には未来の日付を入れてください', 'error');
          } else {
            notify('メール内容を選択してください', 'error');
          }
        };

        return (
          <>
            <Button className={styles.showModal} onClick={showModal} disabled={loading || disabled}>
              実施期間を変更する
            </Button>

            {isOpen && (
              <form>
                <Modal.Modal>
                  <Modal.Header hideModal={hideModal}>実施期間の変更</Modal.Header>
                  <Modal.Body>
                    <div className={styles.modalBody}>
                      <div>ストレスチェックの実施期間を変更します。</div>
                      <div className={styles.dateField}>
                        <div className={styles.selectDate}>
                          <DateField
                            name="surveyEndDate"
                            label="実施期間"
                            onChange={(_, value) => setSurveyEndDate(value)}
                            validate={futureDate}
                            required
                          />
                        </div>
                        <div className={styles.endText}>まで</div>
                      </div>

                      {targetCount > 0 && (
                        <>
                          <div className={styles.checkbox}>
                            <CheckboxField
                              onChange={() => setIsSendMail(!isSendMail)}
                              name="isSendMail"
                              label="変更時に通知メールも送信"
                            />
                          </div>
                          <div className={styles.checkboxText}>通知メールは未回答者にのみ送信されます。</div>
                        </>
                      )}

                      {isSendMail && targetCount > 0 && (
                        <div className={styles.mailField}>
                          <div className={styles.sendCount}>送信人数：{targetCount}人（未回答者）</div>
                          <div className={styles.selectMail}>
                            <SelectField
                              input={{
                                value: selectedEmail,
                                onChange: value => {
                                  setSelectedEmail(value);
                                }
                              }}
                              label="メール内容"
                              name="Mail"
                              simpleValue
                              required
                              clearable={false}
                              options={mailOptions}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className={styles.modalFooter}>
                      <Modal.Buttons>
                        <Button className={styles.cancelButton} onClick={hideModal}>
                          キャンセル
                        </Button>
                        <Button
                          className={styles.sendButton}
                          primary
                          onClick={sendForm}
                          disabledReason={isTaskRunning ? 'ストレスチェック送信中のため送信できません' : null}
                          disabled={!selectedEmail || loading || isTaskRunning || !isFutureDate(surveyEndDate)}>
                          変更
                        </Button>
                      </Modal.Buttons>
                    </div>
                  </Modal.Footer>
                </Modal.Modal>
              </form>
            )}
          </>
        );
      }}
    </Modal>
  );
};

export const ChangeDeadlineForm = reduxForm({
  form: 'ChangeDeadlineForm',
  enableReinitialize: true
})(ChangeDeadline);
