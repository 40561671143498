import { datadogRum } from '@datadog/browser-rum';

if (process.env.ENABLE_DATADOG) {
  datadogRum.init({
    applicationId: '658efd49-e565-4f81-85f2-db912f958b5e',
    clientToken: 'pub81356c2e671fd7e0a7f49bae9aeeca7e',
    site: 'ap1.datadoghq.com',
    service: 'stress',
    env: process.env.SERVER_ENV,
    version: process.env.COMMIT_REF,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [`${process.env.DATADOG_TRACING_ORIGIN}`]
  });
}
