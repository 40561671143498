import React from 'react';
import styles from './Comments.scss';

const FORM_WITH_TOP_COMMENT = [1, 2];
const FORM_WITH_BOTTOM_COMMENT = [1, 2];
const REACTION_CATEGORY_TITLE = {
  japanese: ['イライラ感', '疲労感', '不安感', '抑うつ感'],
  english: ['irritability', 'exhaustion', 'anxiety', 'depression']
};
const CAUSE_COMMENTS_FOR_FORM57 = {
  japanese: [
    '仕事の量的負担、質的負担､対人関係上のストレス､仕事のコントロール度、全てに問題はありませんでした。',
    '仕事の量的負担度が高いようでした。',
    '仕事の質的負担度が高いようです。',
    '仕事の量的負担、質的負担度が高いようでした。',
    '仕事の身体的負担が高いようです。',
    '仕事の量的負担、身体的負担度が高いようでした。',
    '仕事の質的負担、身体的負担が高いようです。',
    '仕事の量的負担、質的負担、身体的負担度が高いようでした。',
    '仕事の対人関係上のストレスが高いようでした。',
    '仕事の量的負担、対人関係上のストレスが高いようでした。',
    '仕事の質的負担、対人関係上のストレスが高いようでした。',
    '仕事の量的負担、質的負担、対人関係でのストレスが高いようでした。',
    '仕事の身体的負担､対人関係上のストレスが高いようです。',
    '仕事の量的負担、身体的負担、対人関係上のストレスが高いようでした。',
    '仕事の質的負担、身体的負担、対人関係のストレスが高いようです。',
    '仕事の量的負担、質的負担、身体的負担、対人関係でのストレス度が高いようでした。',
    '仕事のコントロール度が低いようです。',
    '仕事の量的負担が高く、仕事のコントロール度が低いようでした。',
    '仕事の質的負担が高く、仕事のコントロール度が低いようです。',
    '仕事の量的負担、質的負担度が高く、仕事のコントロール度が低いようでした。',
    '仕事の身体的負担が高く､仕事のコントロール度が低いようです。',
    '仕事の量的負担、身体的負担度が高く、仕事のコントロール度が低いようでした。',
    '仕事の質的負担、身体的負担が高く、仕事のコントロール度が低いようです。',
    '仕事の量的負担、質的負担、身体的負担度が高く、仕事のコントロール度が低いようでした。',
    '仕事の対人関係上のストレスが高く､仕事のコントロール度が低いようです。',
    '仕事の量的負担、対人関係上のストレスが高く、仕事のコントロール度が低いようです。',
    '仕事の質的負担、対人関係上のストレスが高く､仕事のコントロール度が低いようです。',
    '仕事の量的負担、質的負担、対人関係でのストレスが高く、仕事のコントロール度が低いようでした。',
    '仕事の身体的負担､対人関係上のストレスが高く､仕事のコントロール度が低いようです。',
    '仕事の量的負担、身体的負担、対人関係上のストレスが高く、仕事のコントロール度が低いようでした。',
    '仕事の質的負担、身体的負担、対人関係のストレスが高く、仕事のコントロール度が低いようです。',
    '仕事の量的負担、質的負担、身体的負担度、対人関係でのストレスが高く、仕事のコントロール度が低いようでした。'
  ],
  english: [
    'There were no problems with quantitative and qualitative burden of work, stress on relationships, and level of control over work.',
    'There seems to be a high level of quantitative burden of work.',
    'There seems to be a high level of qualitative burden of work.',
    'There seems to be a high level of quantitative and qualitative burden of work.',
    'There seems to be a high level of physical burden of work.',
    'There seems to be a high level of quantitative and physical burden of work.',
    'There seems to be a high level of qualitative and physical burden of work.',
    'There seems to be a high level of quantitative, qualitative, and physical burden of work.',
    'There seems to be a high level of stress regarding relationships of work.',
    'There seems to be a high level of quantitative burden and stress regarding relationships of work.',
    'There seems to be a high level of qualitative burden and stress regarding relationships of work.',
    'There seems to be a high level of quantitative burden, qualitative burden, and stress regarding relationships of work.',
    'There seems to be a high level of physical burden and stress regarding relationships of work.',
    'There seems to be a high level of quantitative burden, physical burden, and stress regarding relationships of work.',
    'There seems to be a high level of qualitative burden, physical burden, and stress regarding relationships of work.',
    'There seems to be a high level of quantitative burden, qualitative burden, physical burden, and stress regarding relationships of work.',
    'There seems to be a low level of level of control over work.',
    'There seems to be a high level of quantitative burden of work, and a low level of level of control over work.',
    'There seems to be a high level of qualitative burden of work, and a low level of level of control over work.',
    'There seems to be a high level of quantitative and qualitative burden of work, and a low level of level of control over work.',
    'There seems to be a high level of physical burden of work, and a low level of level of control over work.',
    'There seems to be a high level of quantitative and physical burden of work, and a low level of level of control over work.',
    'There seems to be a high level of qualitative and physical burden of work, and a low level of level of control over work.',
    'There seems to be a high level of quantitative, qualitative, and physical burden of work, and a low level of level of control over work.',
    'There seems to be a high level of stress regarding relationships of work, and a low level of level of control over work.',
    'There seems to be a high level of quantitative burden and stress regarding relationships of work, and a low level of level of control over work.',
    'There seems to be a high level of qualitative burden and stress regarding relationships of work, and a low level of level of control over work.',
    'There seems to be a high level of quantitative burden, qualitative burden, and stress regarding relationships of work, and a low level of level of control over work.',
    'There seems to be a high level of physical burden and stress regarding relationships of work, and a low level of level of control over work.',
    'There seems to be a high level of quantitative burden, physical burden, and stress regarding relationships of work, and a low level of level of control over work.',
    'There seems to be a high level of qualitative burden, physical burden, and stress regarding relationships of work, and a low level of level of control over work.',
    'There seems to be a high level of quantitative burden, qualitative burden, physical burden, and stress regarding relationships of work, and a low level of level of control over work.'
  ]
};
const CAUSE_COMMENTS_FOR_FORM23 = {
  japanese: [
    '仕事の量的負担、仕事のコントロール度、全てに問題はありませんでした。',
    '仕事の量的負担度が高いようでした。',
    '仕事のコントロール度が低いようです。',
    '仕事の量的負担が高く、仕事のコントロール度が低いようでした。'
  ],
  english: [
    'There were no problems with quantitative burden of work, level of control over work.',
    'There seems to be a high level of quantitative burden of work.',
    'There seems to be a low level of level of control over work.',
    'There seems to be a high level of quantitative burden of work, and a low level of level of control over work.'
  ]
};
const hasStressCause = values => parseCauseValues(values[0].values).some(value => value.level <= 1);
const hasStressReaction = values => values[1].values.some(value => value.level <= 1);

const parseCauseValues = value => {
  // 1, 2, 3, 4, 6番目のみ取り出す
  const arr_1 = value.slice(0, 4);
  const arr_2 = value.slice(5, 6);
  return arr_1.concat(arr_2);
};

const displaySentence = {
  reactions: {
    japanese: 'あなたの現在のストレス反応',
    english: 'Your current stress reaction'
  },
  causes: {
    japanese: 'ストレスの原因となりうる因子',
    english: 'Factors that may cause stress'
  },
  comments: {
    japanese: 'コメント',
    english: 'Comments'
  }
};

export const TopComment = ({ values, formId, language }) => {
  if (!FORM_WITH_TOP_COMMENT.includes(formId)) {
    return <div />;
  }
  const comments = ((hasStressCause, hasStressReaction) => {
    if (hasStressCause && hasStressReaction) {
      return {
        japanese: [
          'あなたのストレス状況はやや高めな状態にあることが窺われます。',
          'ストレスの状態が続くと、心や身体がストレスの原因に対して反応し、その結果として、気分が落ち込む、イライラ感がつのる、疲れる、元気がないといった症状が現れます。このような症状は気分だけでなく、体の不調として現れてくる事もあります。ストレスは、急に仕事が忙しくなったり、ストレスの原因となる要素（仕事に関連したものや、ご家庭での問題）が重なると、急にあなたに重くのしかかってくる可能性もあります。'
        ],
        english: [
          'It can be seen that your stress level is somewhat high',
          'As you continue to be in stress, the mind and body react to the causes of said stress, resulting in depression, frustration, tiredness, and fatigue. These symptoms can manifest not only as mental or psychological problems, but also physical. Stress can also suddenly take its toll on you when factors causing stress such as workload and job or family related problems begin to accumulate'
        ]
      };
    } else if (!hasStressCause && hasStressReaction) {
      return {
        japanese: [
          'あなたのストレス反応の状態は普通より少し高めでした。しかし、仕事上でのストレスの原因となる因子については問題はみられませんでした。',
          'ストレス状態が続くと､心や身体がストレスの原因に対して反応し、その結果として､気分が落ち込む、イライラ感がつのる、疲れる､元気がないといった症状があらわれます。このような症状や気分だけでなく、からだの不調としてあらわれることもあります。'
        ],
        english: [
          'Your stress response was a little higher than average, however there were few problems with stress factors in relation to work.',
          'As you continue to be in stress, the mind and body react to the causes of said stress, resulting in depression, frustration, tiredness, and fatigue. These symptoms can manifest not only as mental or psychological problems, but also physical.'
        ]
      };
    } else if (hasStressCause && !hasStressReaction) {
      return {
        japanese: [
          'あなたのストレス状態は高くありませんが、ストレスの原因となる因子が少しあるようです。',
          '仕事にかかわる時間は一日のうちの多くを占めるので、仕事の量の多さや忙しさ、仕事の性質（注意を要する仕事など）、仕事に対してコントロールできる度合いなど、その他様々な出来事がストレスの原因になり得ます。'
        ],
        english: [
          'You are not very stressed, but there seems to be a few factors causing stress.',
          'Because most of the day is spent working, the amount of work, how busy you are, the nature and amount of attention required, as well as the degree of control you have over work, together with other factors, can be a source of stress.'
        ]
      };
    } else {
      return {
        japanese: [
          'あなたはストレスをあまりかかえておらず、またストレスの原因となる要素もあまり無いようです。',
          'ただし、ストレスは、急に仕事が忙しくなったり、ストレスの原因となる要素（仕事に関連したものや、ご家庭での問題）が重なると、急にあなたに重くのしかかってくる可能性もあります。'
        ],
        english: [
          'You are not very stressed and do not seem to have many causes of stress.',
          'However, stress can suddenly take its toll on you when factors causing stress such as workload, and job or family related problems begin to accumulate.'
        ]
      };
    }
  })(hasStressCause(values), hasStressReaction(values));
  return (
    <div className={styles.topComment}>
      <div className={styles.mainComment}>{comments[language][0]}</div>
      <div className={styles.subComment}>{comments[language][1]}</div>
    </div>
  );
};

export const BottomComment = ({ values, comments, formId, language }) => {
  if (!FORM_WITH_BOTTOM_COMMENT.includes(formId)) {
    return (
      <>
        {comments.map(c => {
          return (
            <div key={c.title} className={styles.commentContent}>
              <p>{c.description}</p>
            </div>
          );
        })}
      </>
    );
  }
  const comment = ((hasStressCause, hasStressReaction) => {
    if (hasStressCause && hasStressReaction) {
      return {
        japanese:
          '仕事の量が多い、仕事がキビシイと考えている人は、もう一度自分の仕事量を見直し、上司、同僚と仕事内容について相談することをお勧めします。周囲の人に協力を仰ぐ事により、事態が解決するかもしれません。 仕事のコントロール度は、自分で仕事の予定や手順を決めることができない時、「仕事のコントロール度」が低くなります。例えば、周囲のスピードや上司の予定に合わせて仕事をするとか、急な仕事の変更がよく起こるために予定が立てられない状況などです。仕事のやり方をうまくして負担量を軽減することができないか、 仕事の進め方を自分で決定できる部分がもっとないか、を考えてみて下さい｡ それが無理な場合は、仕事からストレスを多く受けていることを自覚して、勤務時間外や休日はなるべく仕事を持ち帰らず､リフレッシュに努めましょう。また、一人で悩みを抱え込まずに、周囲に悩みを相談することもよいでしょう。また、産業医や専門家に相談する事も一つの方法です。専門的な助言を受けることによって、自分では気がつかなかった解決法が見つかることもあるでしょう。',
        english:
          "If you have a lot of work and feel it is too difficult, we recommend reviewing your work and consulting with your boss and colleagues as the situation may be resolved by asking the people around you for help. The degree of control you have over your work is considered to be low when you cannot schedule or decide the procedures of working by yourself. For example, you may need to work constantly chasing deadlines or working around your boss' schedule, or you are unable to properly schedule due to a volatile environment. Start thinking about whether you can work well and reduce the burden, or if there are other aspects you can control and modify to manage your workload. If that is not possible, you may experience stress from work. Whenever possible, avoid taking your work home after hours or working on holidays, and share and talk about your problems with another person. Another option is to seek professional care, which may lead you to a solution you did not previously consider."
      };
    } else if (!hasStressCause && hasStressReaction) {
      return {
        japanese:
          '今回、わずかながらストレスのサインが見られました。これをきっかけとして、こころと体の健康管理に気をつけましょう。一人で悩みを抱え込まずに、周囲に悩みを相談することもよいでしょう。また、産業医や専門家に相談する事も一つの方法です。専門的な助言を受けることによって、自分では気がつかなかった解決法が見つかることもあるでしょう。',
        english:
          'This time, there were slight signs of stress. Take this as an opportunity to reflect on and take care of your physical and mental health. It is also advised you talk to the people around you about your problems. Allternatively, you can seek professional care which may lead you to solutions you did not previously consider.'
      };
    } else if (hasStressCause && !hasStressReaction) {
      return {
        japanese:
          'ご自分にとってのストレスの原因となるものとその程度を把握し、それに可能な対処をしていくことで、心や体に大きなストレスがかかるのを未然に防ぐことが可能です。仕事の量が多い、仕事の質がキビシイと考えている人は、もう一度自分の仕事量を見直し、上司、同僚と仕事内容について相談することをお勧めします。周囲の人に協力を仰ぐ事により、事態が解決するかもしれません。仕事のコントロール度は、自分で仕事の予定や手順を決めることができない時、「仕事のコントロール度」が低くなります。例えば、周囲のスピードや上司の予定に合わせて仕事をするとか、急な仕事の変更がよく起こるために予定が立てられない状況などです。仕事のやり方をうまくして負担量を軽減することができないか、 仕事の進め方を自分で決定できる部分がもっとないか、を考えてみて下さい｡ それが無理な場合は、仕事からストレスを多く受けていることを自覚して、勤務時間外や休日はなるべく仕事を持ち帰らず､リフレッシュに努めましょう。',
        english:
          "Through understanding the causes and extent of stress you experience, and taking measures to deal with it, you can prevent a great deal of stress on your mind and body. If you have a lot of work and suffer from poor working conditions, we recommend reviewing and reflecting upon your work and consulting with your boss and colleagues. The situation may be resolved by asking the people around you for help. The degree of control you have over your work is considered to be low when you cannot schedule or decide the procedures of working by yourself. For example, you may need to work constantly chasing deadlines or working around your boss' schedule, or you are unable to properly schedule due to a volatile environment. Start thinking about whether you can work well and reduce the burden, or if there are other aspects you can control and modify to manage your workload. If that is not possible, you may experience stress from work. Whenever possible, avoid taking your work home after hours or working on holidays, and share and talk about your problems with another person."
      };
    } else {
      return {
        japanese:
          '今回の調査時点では、問題はありませんでしたが、普段からストレスをためこまず、気分転換をはかるようにこころがけることをお勧めいたします。',
        english:
          'At the time of this survey, there were no problems in regards to stress, but we still recommend you try to maintain a stress-free environment and a positive mentality.'
      };
    }
  })(hasStressCause(values), hasStressReaction(values));
  const reactionComment = (formId => {
    switch (formId) {
      case 1:
        return {
          japanese: makeReactionCommentForForm57Ja(values[1].values),
          english: makeReactionCommentForForm57En(values[1].values)
        };
      case 2:
        return {
          japanese: makeReactionCommentForForm23Ja(values[1].values),
          english: makeReactionCommentForForm23En(values[1].values)
        };
      default:
        return { japanese: '', english: '' };
    }
  })(formId);
  const causeComment = makeCauseComment(values[0].values, formId, language);
  return (
    <div>
      <div className={styles.commentContent}>
        <h4>{displaySentence.reactions[language]}</h4>
        <p>{reactionComment[language]}</p>
      </div>
      <div className={styles.commentContent}>
        <h4>{displaySentence.causes[language]}</h4>
        <p>{causeComment}</p>
      </div>
      <div className={styles.commentContent}>
        <h4>{displaySentence.comments[language]}</h4>
        {comment[language]}
      </div>
    </div>
  );
};

const makeReactionCommentForForm57Ja = values => {
  if (values.every(value => value.level > 1)) {
    return '活気、イライラ感、疲労感、不安感、抑うつ感、身体愁訴、全てに問題はみられませんでした。';
  }
  const hasLowVitality = values[0].level <= 1;
  const hasPhysicalComplaint = values[5].level <= 1;
  const hasOtherReaction = values.slice(1, 5).some(value => value.level <= 1);
  let comment = '';
  if (hasLowVitality) {
    if (hasPhysicalComplaint || hasOtherReaction) {
      comment += '活気が低く、';
    } else {
      comment += '活気が低い';
    }
  }
  if (hasOtherReaction) {
    comment += values
      .slice(1, 5)
      .map((value, index) => {
        if (value.level <= 1) {
          return REACTION_CATEGORY_TITLE['japanese'][index];
        }
        return '';
      })
      .filter(v => v)
      .join('・');
    comment += hasPhysicalComplaint ? 'が高く、' : 'が高い';
  }
  if (hasPhysicalComplaint) {
    if (hasLowVitality && hasOtherReaction) {
      comment += 'また、';
    }
    comment += '身体愁訴が多い';
  }
  comment += 'ようです';
  return comment;
};

const makeReactionCommentForForm57En = values => {
  if (values.every(value => value.level > 1)) {
    return 'There were no problems found with vitality, irritability, exhaustion, anxiety, depression, and physical conplaint.';
  }
  const hasLowVitality = values[0].level <= 1;
  const hasPhysicalComplaint = values[5].level <= 1;
  const hasOtherReaction = values.slice(1, 5).some(value => value.level <= 1);
  let comment = 'There seems to be ';
  if (hasLowVitality) {
    if (hasPhysicalComplaint && hasOtherReaction) {
      comment += 'a low lovel of vitality, ';
    } else if (hasPhysicalComplaint || hasOtherReaction) {
      comment += 'a low lovel of vitality, and ';
    } else {
      comment += 'a low level of vitality.';
    }
  }
  if (hasOtherReaction) {
    comment += 'a high level of ';
    const reactions = values
      .slice(1, 5)
      .map((value, index) => {
        if (value.level <= 1) {
          return REACTION_CATEGORY_TITLE['english'][index];
        }
        return '';
      })
      .filter(v => v);
    const lastReaction = reactions.pop();
    comment += reactions.join(', ');
    if (reactions.length > 1) {
      comment += ', and ';
    } else if (reactions.length > 0) {
      comment += ' and ';
    }
    comment += lastReaction;
    comment += hasPhysicalComplaint ? ', and ' : '.';
  }
  if (hasPhysicalComplaint) {
    comment += 'a lot of physical complaint.';
  }
  return comment;
};

const makeReactionCommentForForm23Ja = values => {
  if (values.every(value => value.level > 1)) {
    return '疲労感、不安感、抑うつ感、身体愁訴、全てに問題はみられませんでした。';
  }
  const hasPhysicalComplaint = values.slice(3, 5).some(value => value.level <= 1);
  const hasOtherReaction = values.slice(0, 3).some(value => value.level <= 1);
  let comment = '';
  if (hasOtherReaction) {
    comment += values
      .slice(0, 3)
      .map((value, index) => {
        if (value.level <= 1) {
          return REACTION_CATEGORY_TITLE['japanese'][index + 1];
        }
        return '';
      })
      .filter(v => v)
      .join('・');
    comment += hasPhysicalComplaint ? 'が高く、' : 'が高い';
  }
  if (hasPhysicalComplaint) {
    comment += '身体愁訴が多い';
  }
  comment += 'ようです';
  return comment;
};

const makeReactionCommentForForm23En = values => {
  if (values.every(value => value.level > 1)) {
    return 'There were no problems found with exhaustion, anxiety, depression, and physical complaint.';
  }
  const hasPhysicalComplaint = values.slice(3, 5).some(value => value.level <= 1);
  const hasOtherReaction = values.slice(0, 3).some(value => value.level <= 1);
  let comment = 'There seems to be ';
  if (hasOtherReaction) {
    comment += 'a high level of ';
    const reactions = values
      .slice(0, 3)
      .map((value, index) => {
        if (value.level <= 1) {
          return REACTION_CATEGORY_TITLE['english'][index + 1];
        }
        return '';
      })
      .filter(v => v);
    const lastReaction = reactions.pop();
    comment += reactions.join(', ');
    if (reactions.length > 0) {
      comment += ', and ';
    }
    comment += lastReaction;
    comment += hasPhysicalComplaint ? ', and ' : '.';
  }
  if (hasPhysicalComplaint) {
    comment += 'a lot of physical complaint.';
  }
  return comment;
};

const makeCauseComment = (values, formId, language) => {
  const reducer = (value, cur) => {
    return value + cur;
  };
  if (formId === 1) {
    values = parseCauseValues(values);
  }
  const commentNumber = values
    .map((value, index) => {
      if (value.level <= 1) {
        return Math.pow(2, index);
      } else {
        return 0;
      }
    })
    .reduce(reducer);
  switch (formId) {
    case 1:
      return CAUSE_COMMENTS_FOR_FORM57[language][commentNumber];
    case 2:
      return CAUSE_COMMENTS_FOR_FORM23[language][commentNumber];
    default:
      return '';
  }
};
