import Rollbar from 'rollbar';

const rollbarConfig = {
  accessToken: '24b46d0231094057b97b0b085a1510fd',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.SERVER_ENV
  },
  enabled: !!process.env.ENABLE_ROLLBAR,
  scrubFields: ['password', 'value', 'token', 'variables']
};

Rollbar.init(rollbarConfig);

(window as any).rollbar = Rollbar;
