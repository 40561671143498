export interface Survey {
  id: number;
  createdAt: Date;
  surveyEndDate: Date;
  surveyForm: {
    id: number;
    title: string;
    hasNotableCheck: any;
    surveyFormNotableCheck: any;
    surveyFormQuestionDisplayCategories: any[];
  };
  employeeSurveys: any[];
  employeeCount: number;
  answeredCount: number;
  notableCount: number;
}

export const initialSurveyForm = {
  id: 0,
  title: '',
  hasNotableCheck: false,
  surveyFormNotableCheck: {},
  surveyFormQuestionDisplayCategories: []
};

export const initialSurvey: Survey = {
  id: 0,
  createdAt: new Date(),
  surveyEndDate: new Date(),
  surveyForm: initialSurveyForm,
  employeeSurveys: [],
  employeeCount: 0,
  answeredCount: 0,
  notableCount: 0
};
