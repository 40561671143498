import React, { useContext } from 'react';
import { gql } from '@apollo/client';
import { useQuery } from './Graphql';
import { LoadingPage } from './LoadingPage';
import Layout from '../Layout';
import { Route, Switch } from 'react-router';
import { InvitationConfirmation } from '../pages/InvitationConfirmation';
import { Login } from '../pages/Login';
import { ForgotPassword } from '../pages/ForgotPassword';
import { ResetPassword } from '../pages/ResetPassword';
import { isErrorWithMessage } from './utils';

interface IContext {
  client: any;
  user: any;
}

const initialContext = {
  client: null,
  user: null
};

const Context = React.createContext<IContext>(initialContext);

const SESSION_QUERY = gql`
  query {
    user {
      id
      email
      name
      roleType
      employee {
        firstName
        lastName
      }
    }
    client {
      id
      name
      clientCode
      isVerified
    }
  }
`;

export const SessionProvider = ({ children }) => {
  try {
    const { data } = useQuery(SESSION_QUERY, { fetchPolicy: 'no-cache' });
    if (!data) {
      return (
        <Layout sidebar showSession={false}>
          <LoadingPage />
        </Layout>
      );
    }
    const { user, client } = data;
    return <Context.Provider value={{ user, client }}>{children}</Context.Provider>;
  } catch (err) {
    if (isErrorWithMessage(err, '401')) {
      return (
        <Layout sidebar={false} showSession={false}>
          <Switch>
            <Route path="/invitation_confirmation" exact component={InvitationConfirmation} />
            <Route path="/login" exact component={Login} />
            <Route path="/forgot_password" exact component={ForgotPassword} />
            <Route path="/reset_password" exact component={ResetPassword} />
            <Route
              component={() => (
                <div className="l-wrap-xs u-pt100">
                  <div className="l-box-message">
                    <div>
                      認証が切れました。
                      <br />
                      もう一度ストレスチェックに入ってください。
                    </div>
                  </div>
                </div>
              )}
            />
          </Switch>
        </Layout>
      );
    }

    return (
      <Layout sidebar={false} showSession={false}>
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">
            <p>エラーが発生しました。もう一度お試しください。</p>
          </div>
        </div>
      </Layout>
    );
  }
};

export const useSession = () => useContext(Context);
