import React from 'react';
import { Field } from 'redux-form';
import { MultiTagFilteringFieldRow } from 'jbc-front/components/SearchForm';
import styles from 'jbc-front/components/SearchForm.scss';
import { useQuery, gql } from '@apollo/client';
import { withNull } from '../components/utils';
import { GroupsSelectBox, OfficesSelectBox } from '../components';

const ADDITIONAL_SEARCH_QUERY = gql`
  query additionalSearchQuery {
    client {
      id
      offices {
        id
        name
      }
      employmentTypes
      groups
      positions
    }
  }
`;

export const AdditionalSearchFields = () => {
  const { data, loading } = useQuery(ADDITIONAL_SEARCH_QUERY, { fetchPolicy: 'network-only' });
  if (loading) return null;
  return (
    <div className={styles.details}>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>適用事業所</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <Field
              name="offices"
              component={OfficesSelectBox}
              options={data.client.offices.map(office => ({
                value: '' + office.id,
                label: office.name
              }))}
            />
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>グループ</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <Field
              name="groups"
              component={GroupsSelectBox}
              options={data.client.groups.map(group => ({
                value: group,
                label: group
              }))}
            />
          </div>
        </div>
      </div>
      <MultiTagFilteringFieldRow
        header="役職"
        fieldName="positions"
        list={withNull(data.client.positions.map(type => ({ name: type, id: type })))}
      />
      <MultiTagFilteringFieldRow
        header="雇用形態"
        fieldName="employmentTypes"
        list={withNull(data.client.employmentTypes.map(type => ({ name: type, id: type })))}
      />
    </div>
  );
};
