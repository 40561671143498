import React from 'react';
import { Field } from 'redux-form';
import { MultiTagFilteringFieldRow } from 'jbc-front/components/SearchForm';
import styles from 'jbc-front/components/SearchForm.scss';
import { useQuery, gql } from '@apollo/client';
import { GroupsSelectBox, OfficesSelectBox } from '../components';

const EMPLOYEE_SURVEY_ADDITIONAL_SEARCH_QUERY = gql`
  query employeeSurveyAdditionalSearchQuery {
    client {
      id
      offices {
        id
        name
      }
      groups
    }
  }
`;

const notability = [
  { id: 'false', name: '対象外' },
  { id: 'true', name: '高ストレス' }
];

const answeredStates = [
  { id: 'false', name: '未回答' },
  { id: 'true', name: '回答済み' }
];

export const AdditionalSearchFields = ({ isPractitioner }) => {
  const { data, loading } = useQuery(EMPLOYEE_SURVEY_ADDITIONAL_SEARCH_QUERY, { fetchPolicy: 'network-only' });
  if (loading) return null;
  return (
    <div className={styles.details}>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>適用事業所</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <Field
              name="offices"
              component={OfficesSelectBox}
              options={data.client.offices.map(office => ({
                value: office.name,
                label: office.name
              }))}
            />
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>グループ</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <Field
              name="groups"
              component={GroupsSelectBox}
              options={data.client.groups.map(group => ({
                value: group,
                label: group
              }))}
            />
          </div>
        </div>
      </div>
      {isPractitioner && <MultiTagFilteringFieldRow header="高ストレス対象" fieldName="isNotable" list={notability} />}
      <MultiTagFilteringFieldRow header="回答状況" fieldName="answerFinished" list={answeredStates} />
    </div>
  );
};
