import React, { useContext, useEffect } from 'react';
import AsyncTaskLoading from './AsyncTaskLoading';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { useMutation } from './Graphql';
import useReactRouter from 'use-react-router';

export const ASYNC_TASK_FRAGMENT = gql`
  fragment AsyncTaskFields on AsyncTask {
    id
    taskType
    status
    error
    excutedAt
    endedAt
  }
`;

const ASYNC_TASK = gql`
  query asyncTask {
    client {
      id
      asyncTask {
        ...AsyncTaskFields
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const CONFIRM = gql`
  mutation confirmAsyncTask($id: ID!) {
    confirmAsyncTask(input: { id: $id }) {
      result
    }
  }
`;

const calcPercentage = task =>
  Math.min(Math.max(Math.floor(100 * (1 - Math.exp(-moment().diff(task.excutedAt, 'second') / 7))), 0), 99);

const taskTypeMessages = {
  send_request: {
    running: 'ストレスチェック送信中のため一部操作ができません',
    finished: 'ストレスチェックの送信が完了しました',
    error: 'ストレスチェックの送信でエラーが発生しました。'
  },
  resend_request: {
    running: '受検勧奨メール送信中のため一部操作ができません',
    finished: '受検勧奨メールの送信が完了しました',
    error: '受検勧奨メールの送信でエラーが発生しました。'
  },
  default: {
    running: '送信中のため一部操作ができません',
    finished: '送信が完了しました',
    error: 'エラーが発生しました'
  }
};

const getTaskMessage = task => {
  const messages = taskTypeMessages[task.taskType] || taskTypeMessages.default;
  switch (task.status) {
    case 'in_progress':
    case 'waiting':
      return messages.running;
    case 'success':
      return messages.finished;
    case 'failed':
      return (
        <p>
          {messages.error}
          <br />
          <Link to="/dashboard" className="u-txt-link">
            ダッシュボード
          </Link>
          より確認してください。
        </p>
      );
    default:
      return messages.error;
  }
};

const Context = React.createContext({
  task: null as any,
  refetch: () => {},
  taskRunningProps: {},
  confirm: () => {}
});

export const useAsyncTask = () => useContext(Context);

const useAsyncTaskData = () => {
  const { data, loading, error, startPolling, stopPolling, refetch } = useQuery(ASYNC_TASK, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: {}
  });
  const confirm = useMutation(CONFIRM).mutation;
  const task = data?.client?.asyncTask;
  const confirmAndRefetch = async () => {
    await confirm({ variables: { id: task.id } });
    refetch();
  };

  return {
    loading,
    error,
    task,
    startPolling,
    stopPolling,
    refetch,
    confirmAndRefetch
  };
};

export const AsyncTask = ({ children }) => {
  const { loading, error, task, startPolling, stopPolling, refetch, confirmAndRefetch } = useAsyncTaskData();
  const context = useAsyncTask();

  if (loading) {
    return (
      <Context.Provider value={{ ...context, refetch }}>
        {children}
        {null}
        {null}
        {null}
      </Context.Provider>
    );
  }

  if (error || !task) {
    return (
      <Context.Provider value={{ ...context, refetch }}>
        {children}
        {null}
        <Controller startPolling={startPolling} stopPolling={stopPolling} stop={error || !task} refetch={refetch} />
        {null}
      </Context.Provider>
    );
  }

  const taskStatusText = {
    waiting: '0%',
    in_progress: `${calcPercentage(task)}%`,
    success: '完了',
    default: 'エラー'
  };

  const getTaskStatusText = task => taskStatusText[task.status] || taskStatusText.default;

  return (
    <Context.Provider
      value={{
        task,
        refetch,
        taskRunningProps: ['waiting', 'in_progress'].includes(task.status)
          ? { disabled: true, disabledReason: getTaskMessage(task) }
          : {},
        confirm: confirmAndRefetch
      }}>
      {children}
      <AsyncTaskLoading
        error={task.status === 'failed'}
        done={task.status === 'success'}
        message={getTaskMessage(task)}
        text={getTaskStatusText(task)}
        onCloseClick={confirmAndRefetch}
      />
      <Controller
        startPolling={startPolling}
        stopPolling={stopPolling}
        stop={task.status !== 'waiting' && task.status !== 'in_progress'}
        refetch={refetch}
      />
    </Context.Provider>
  );
};

const Controller = ({ startPolling, stopPolling, stop, refetch }) => {
  const { location } = useReactRouter();

  useEffect(() => {
    if (!stop) {
      startPolling(3000);
      return () => stopPolling();
    }
  }, [startPolling, stopPolling, stop]);

  useEffect(() => {
    refetch();
  }, [location.pathname, refetch]);

  return null;
};

export default AsyncTask;
