import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useQuery, LoadingPage, getTitle, ResultDetail } from '../components';
import { initialEmployeeSurvey } from '../entity';
import { Panel } from '../components/PrimaryPanel';
import styles from './PersonalResultDetail.scss';
import Select from 'react-select';
import useReactRouter from 'use-react-router';
import _ from 'lodash';

const PERSONAL_RESULT_DETAIL = gql`
  query personalResultDetail($employeeSurveyId: Int!) {
    employeeSurveys(employeeSurveyId: $employeeSurveyId) {
      id
      isProvided
      implementationSurvey {
        id
        createdAt
        surveyForm {
          id
          title
          hasNotableCheck
          surveyFormNotableCheck {
            id
            notableCheckTitles {
              id
              language
              title
              description
            }
          }
          surveyFormQuestionDisplayCategories {
            id
            categoryNumber
            categoryTitles {
              id
              language
              title
            }
            surveyFormQuestionDisplayChildCategories {
              id
              categoryNumber
              childCategoryTitles {
                id
                language
                title
              }
            }
          }
        }
      }
      employeeSurveyCalculatedValues {
        id
        title
        categoryNumber
        value
        level
        employeeSurveyCalculatedChildValues {
          id
          title
          childCategoryNumber
          value
          level
        }
      }
      answerFinished
      isNotable
      employee {
        id
        firstName
        lastName
        language
      }
      employeeSurveyComments {
        id
        title
        description
      }
    }
  }
`;

const ResultDetailPanel = ({ id }) => {
  const { history } = useReactRouter();
  const personalResultDetail = useQuery(PERSONAL_RESULT_DETAIL, {
    variables: { employeeSurveyId: parseInt(id) },
    fetchPolicy: 'no-cache'
  });
  const [selectedEmployeeSurvey, setSelectedEmployeeSurvey] = useState(initialEmployeeSurvey);
  useEffect(() => {
    if (personalResultDetail.data?.employeeSurveys) {
      setSelectedEmployeeSurvey(_.find(personalResultDetail.data.employeeSurveys, { id: id }));
    }
  }, [personalResultDetail.data]);
  if (personalResultDetail.loading) {
    return <LoadingPage />;
  }
  const surveyOptions = personalResultDetail.data.employeeSurveys.map(employeeSurvey => {
    return {
      label: getTitle(employeeSurvey.implementationSurvey),
      value: employeeSurvey
    };
  });
  return (
    <>
      <Select
        name="EmployeeSurveyForm"
        className={styles.select}
        simpleValue
        required
        clearable={false}
        value={{ label: getTitle(selectedEmployeeSurvey.implementationSurvey), value: selectedEmployeeSurvey }}
        options={surveyOptions}
        onChange={(selected: any) => {
          history.push(`/personal_results/details/${selected?.id}`);
        }}
      />
      <Panel>
        <ResultDetail employeeSurvey={selectedEmployeeSurvey} showModal={null} language={'japanese'} />
      </Panel>
    </>
  );
};

export const PersonalResultDetail = ({
  match: {
    params: { id }
  }
}) => {
  return (
    <>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">個人結果</h1>
      </div>
      <div className={styles.contentsWrap}>
        <div className="l-wrap-xl">
          <ResultDetailPanel id={id} />
        </div>
      </div>
    </>
  );
};
